import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AssetStatus } from 'src/app/models/building.model';
import {
  BuildingType,
  ClientType,
  Customer,
  CustomerEdit,
  CustomerType,
  MeterStatus,
  Neighborhood,
  SltStatus,
} from 'src/app/models/customer.model';
import { EntityType, ImageType } from 'src/app/models/image.model';
import { User } from 'src/app/models/user.model';
import { BuildingService } from 'src/app/services/building.service';
import { CustomerService } from 'src/app/services/customer.service';
import { MeterService } from 'src/app/services/meter.service';
import { UserService } from 'src/app/services/user.service';
import { HistoryOutput } from 'src/app/models/history.model';
import { PermissionsEnum } from 'src/app/models/role.model';
import { TranslationService } from 'src/app/services/translation.service';
import { NextDataQuality } from 'src/app/models/dataQuality.model';
import { PermissionsService } from 'src/app/services/permissions.service';
import { RecallType } from 'src/app/models/recallType.model';
import { AssetStatusEnum } from 'src/app/models/assetStatus.model';
import { MeterType } from '../../../models/meter.model';
import { OptionObj } from 'src/app/models/util.model';
import { KeyValuePair } from 'src/app/models/keyValueItem.model';
import { AnalyticsService } from 'src/app/services/analytics.service';

@Component({
  selector: 'edit-customer',
  templateUrl: './edit-customer.component.html',
  styleUrls: ['./edit-customer.component.scss'],
})
export class EditCustomerComponent implements OnInit {
  PermissionsEnum = PermissionsEnum;

  AssetAssignType = AssetStatusEnum;
  assetAssignType: AssetStatusEnum;

  customer: CustomerEdit = new CustomerEdit();
  longitude: number = 0;
  latitude: number = 0;
  customerHeaderInfo: Customer = new Customer();
  mobileUsers: User[] = [];
  assignedUserId: number = 0;

  ImageType = ImageType;
  EntityType = EntityType;
  billImage: File | null;
  buildingImage: File | null;
  selectedImage: string = '';

  editEnabled: boolean = false;
  meterEditEnabled: boolean = false;
  // f11Options: F11Crud[] = [];
  // f33Options: F33Crud[] = [];
  // dtOptions: OptionObj[] = [];
  sltStatus: string = this.translationService.getByKeyFromStorage('SltStatus');
  sltOptions: OptionObj[] = [];
  selectedSltStatus: OptionObj = {
    key: 0,
    value: '',
  };
  meterTypeOptions: OptionObj[] = [];
  openApproveModal: boolean = false;
  openRejectModal: boolean = false;
  editFieldsPopulated: boolean = false;
  updateStatusClicked: boolean = false;
  rejectionReason = '';
  customerId = 0;
  @ViewChild('closeModal') closeModal: ElementRef;
  @ViewChild('closeDQModal') closeDQModal: ElementRef;

  @ViewChild('openUpdateDescriptionModal')
  openUpdateDescriptionModal: ElementRef;
  @ViewChild('dropdownContainer') dropdownContainer: ElementRef;
  @ViewChild('userFilterInput') userFilter: ElementRef;

  filterText: string = '';
  isDropdownOpened: boolean = false;
  filteredMobileUsers: User[] = [];
  tempAssignedUserId: number = 0;
  buName: string = this.translationService.getByKeyFromStorage('BU');
  utName: string = this.translationService.getByKeyFromStorage('UT');
  contractNumber: string =
    this.translationService.getByKeyFromStorage('Contract number');
  postCodeName: string =
    this.translationService.getByKeyFromStorage('PostCode');
  history: HistoryOutput = new HistoryOutput();
  imgElement: any;
  imgZoomFullContainer: any;
  currentRotation: number = 0;
  onlyView: boolean = false;
  isDataQuality: boolean = false;
  dtName: string = this.translationService.getByKeyFromStorage('DTNumber');
  isMapVisible: boolean = false;
  showRecallMenu: boolean = false;
  recallAssignedAllowed: boolean = false;
  recallValidatedAllowed: boolean = false;

  // Fields visibility props
  isGhanaPostFieldsVisible: boolean = true;
  isSPNFieldVisible: boolean = true;
  isServiceTypeFieldVisible: boolean = true;
  isActualTariffFieldVisible: boolean = true;
  isMeterSlrnFieldVisible: boolean = true;
  isImageMeterFieldVisible: boolean = true;
  isBackViewPictureFieldVisible: boolean = true;
  isRegularizationUploadVisible:boolean = true;
  customerTypeOptions: OptionObj[] = [];
  customerType = CustomerType;
  meterStatus = MeterStatus;
  clientTypeOptions: OptionObj[] = [];
  clientType = ClientType;
  neighborhoodOptions: OptionObj[] = [];
  neighborhood = Neighborhood;
  numberOfRoomsOptions : number[];
  activityList : KeyValuePair[]=[]
  permiseList : KeyValuePair[]=[]
 
  activityOptions : string[]=[]
  permiseOptions : string[]=[]
  
  subactivityOptions : string[]=[]
  permiseCategoryOptions : string[]=[]

  modalRejectionReason?: string = '';

  constructor(
    private activatedRoute: ActivatedRoute,
    private customerService: CustomerService,
    private toastr: ToastrService,
    private meterService: MeterService,
    private buildingService: BuildingService,
    private userService: UserService,
    public permissionsService: PermissionsService,
    private router: Router,
    private translationService: TranslationService,
    private el: ElementRef,
    private analyticsService: AnalyticsService, 
    private renderer: Renderer2  ) {}

  ngOnInit(): void {
    this.setFieldsVisibility();
    var path = this.activatedRoute.snapshot.url[0].path;
    if(path == 'report-view') {
      this.onlyView = true;
    }
    else if(path == 'data-quality') {
      this.isDataQuality = true;
    }
    const customerId = this.activatedRoute.snapshot.paramMap.get('id');
    this.customerId = Number(customerId);
    this.imgElement = this.el?.nativeElement?.querySelector(
      '.ngxImageZoomThumbnail'
    );
    this.imgZoomFullContainer =
      this.el?.nativeElement?.querySelector('.ngxImageZoomFull');
    this.getOptions();
    this.getActivities();
    this.getPermiseTypes();
    this.getCustomer(customerId);
  }

  setModalType(type: AssetStatusEnum) {
    this.assetAssignType = type;
  }
  regularize(){

    this.customerService.regularizeCustomer(this.customerId).subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.toastr.success('Successfully regularized customer');
          window.location.reload();
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });

  }
  getMeterTypesForOptions() {
    this.meterService.getMeterTypesForOptions().subscribe(
      (response) => {
        this.meterTypeOptions = response.body.data;
      },
      (error) => {
        this.toastr.error('An error occurred.');
      }
    );
  }

  getSltStatusesForOptions() {
    this.customerService.getSltStatusesForOptions().subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.sltOptions = responseData.data;
        } else if (response?.status == 204) {
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }


  private getCustomer(customerId: any) {
    this.customerService.get(customerId).subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.customer = new CustomerEdit(responseData.data);
          console.log(this.customer)

          this.longitude = this.customer.building.longitude;
          this.latitude = this.customer.building.latitude;
          this.assignedUserId =
            this.customer.building.assignedUserId !== 0
              ? this.customer.building.assignedUserId
              : 0;
          this.customer.feederId = this.customer.dt?.feeder?.id;
          this.customer.dtId = this.customer.dt ? this.customer.dt.id : 0;
          this.customer.meterTypeId = this.customer.meter?.meterType
            ? this.customer.meter?.meterType?.id
            : 0;
          this.customer.meterNo = this.customer.meter
            ? this.customer.meter.number
            : '';
          this.filterText = this.customer.building.assignedUser
            ? this.customer.building.assignedUser
            : '';
          this.tempAssignedUserId = this.assignedUserId;
          this.customer.closestLandmark =
            this.customer.building?.closestLandmark;
          this.customer.spn = this.customer.contractNumber;
          this.formatHeaderInfo(this.customer);
          this.selectedSltStatus.key = this.customer.sltStatus?.id;
          this.selectedSltStatus.value = this.customer.sltStatus?.value;

          if(this.customer.dataValidationReview != null && this.customer.dataValidationReview.length > 0) {
            this.modalRejectionReason = this.customer.dataValidationReview.find(x => x.key == 'Reason for rejection')?.value;
          }
        } else {
          this.toastr.error(responseData?.message);
        }

        if (
          this.permissionsService.isCustomerRecallAssignedAllowed &&
          !(
            this.customer.dataQualityStatus?.toString() != 'Rejected' &&
            this.customer.building.status != 'Assigned'
          )
        ) {
          this.recallAssignedAllowed = true;
        }

        if (
          this.permissionsService.isCustomerRecallValidatedAllowed &&
          this.customer.dataQualityId != 0
        ) {
          this.recallValidatedAllowed = true;
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }

  getMobileUsers() {
    this.userService
      .getMobileUsers(this.customer.area.id, this.filterText)
      .subscribe({
        next: (response) => {
          const responseData = response.body;
          if (responseData?.status === 200 || responseData?.status === 'OK') {
            this.mobileUsers = responseData.data;
            if (this.mobileUsers.length == 0) {
              this.isDropdownOpened = false;
            } else {
              this.isDropdownOpened = true;
            }
          } else {
            this.toastr.error(responseData?.message);
          }
        },
        error: (_) => {
          this.toastr.error('Error occured');
        },
      });
  }
  openDropdown() {
    this.isDropdownOpened = true;
  }
  fileBrowserHandler($event: any, type: ImageType) {
    if (
      type === ImageType.Bill ||
      type === ImageType.Card ||
      type === ImageType.Meter ||
      type === ImageType.MeterNumber ||
      type === ImageType.CardBackViewImage
    ) {
      this.billImage = $event.target.files.item(0);
      const obj = {
        imageType: type,
        customerId: this.customer.id,
        guid:this.customer.guid
      };
      if (!this.billImage) {
        this.toastr.error('Image data is missing');
        return
      }
      this.customerService
        .uploadPicture(obj, this.billImage, this.customer.guid)
        .subscribe({
          next: (response) => {
            const responseData = response.body;
            if (responseData?.status === 200 || responseData?.status === 'OK') {
              if (type === ImageType.Bill)
                this.customer.billImageUrl = responseData.data.url;
              else if (type === ImageType.Card)
                this.customer.cardImageUrl = responseData.data.url;
              else if (type === ImageType.Meter)
                this.customer.meterImageUrl = responseData.data.url;
              else if (type === ImageType.MeterNumber)
                this.customer.meterNumberImageUrl = responseData.data.url;
              else if (type === ImageType.CardBackViewImage)
                this.customer.backCardViewImageUrl = responseData.data.url;
              this.toastr.success('Successfully added image');
            } else {
              this.toastr.error(responseData?.message);
            }
          },
          error: (_) => {
            this.toastr.error('Error occured');
          },
        });
      return;
    }

    this.buildingImage = $event.target.files.item(0);
    const obj = {
      imageType: type,
      buildingId: this.customer.building.id,
      guid: this.customer.building.guid
    };
    if (!this.buildingImage) {
        this.toastr.error('Image data is missing');
        return
    }
    this.buildingService.uploadPicture(obj, this.buildingImage).subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.customer.buildingImages.push(responseData.data);
          this.toastr.success('Successfully added image');
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }

  getBuildingMountedImage() {
    return this.customer.buildingImages.find(
      (x) => x.imageType === 'MountedTag'
    )?.url;
  }

  getFrontViewImage(id: number) {
    return this.customer.buildingImages.filter(
      (x) => x.imageType === 'Frontview'
    )[id]?.url;
  }

  edit() {
    this.editEnabled = true;
    this.meterEditEnabled = this.editEnabled && !!this.customer.meter.number;
    if (!this.editFieldsPopulated) {
      // this.getFeedersForOptions();
      this.getSltStatusesForOptions();
      this.getMeterTypesForOptions();
      // if(this.customer?.feederId) {
      //   this.getDtsForOptions(this.customer.feederId);
      // }
      this.setDependant();
      this.editFieldsPopulated = true;
    }
  }

  save() {
    if (this.validateEdit()) {
      if (this.customer.meter.meterType == null) {
        this.customer.meter.meterType = new MeterType();
        this.customer.meter.meterType.id = this.customer.meterTypeId;
      } else {
        this.customer.meter.meterType.id = this.customer.meterTypeId;
        this.customer.meter.lastUpdateTime = null;
      }
      if (
        this.customer.sltStatus.id !== this.selectedSltStatus.key &&
        this.selectedSltStatus.key !== 0
      ) {
        this.customer.sltStatus.id = this.selectedSltStatus.key;
        this.customer.sltStatus.value = this.selectedSltStatus.value;
      }
      if (!this.customer.accountNumber)
        this.customer.accountNumber = null;

      this.analyticsService.logCustomEvent('CUSTOMER DETAILS: Edit', {
        customer_id: this.customer.id
      });

      this.customerService.addCustomer(this.customer).subscribe({
        next: (response) => {
          const responseData = response.body;
          this.openUpdateDescriptionModal?.nativeElement?.click();
          if (responseData?.status === 200 || responseData?.status === 'OK') {
            this.editEnabled = false;
            this.formatHeaderInfo(this.customer);
            window.location.reload();
            this.toastr.success('Successfully edited customer');
          } else {
            this.toastr.error(responseData?.message);
          }
        },
        error: (_) => {
          this.toastr.error('Error occured');
        },
      });
    }
  }

  formatHeaderInfo(customer: CustomerEdit) {
    this.customerHeaderInfo.tenantName = customer.tenantName;
    this.customerHeaderInfo.slrn = customer.building.slrn;
    this.customerHeaderInfo.email = customer.email;
    this.customerHeaderInfo.phoneNumber = customer.phoneNumber;
    this.customerHeaderInfo.physicalAddress = customer.physicalAddress;
  }

  assignTask(): void {
    if (
      this.mobileUsers.filter((x) => x.fullName == this.filterText.trim())
        .length > 0
    ) {
      this.assignedUserId = this.mobileUsers.filter(
        (x) => x.fullName == this.filterText.trim()
      )[0].id;
      this.tempAssignedUserId = this.assignedUserId;
    } else {
      this.tempAssignedUserId = 0;
    }
    if (this.tempAssignedUserId != 0) {
      
      this.analyticsService.logCustomEvent('CUSTOMER DETAILS: Assign/Retag task', {
        slrn: this.customer.building.slrn
      });

      this.buildingService
        .assignBuilding(
          this.customer.building.id,
          this.assignedUserId,
          this.assetAssignType,
          this.isDataQuality
        )
        .subscribe({
          next: (response) => {
            const responseData = response.body;
            if (responseData?.status === 200 || responseData?.status === 'OK') {
              this.closeModal?.nativeElement?.click();
              this.toastr.success('Successfully assigned user');
              window.location.reload();
            } else {
              this.toastr.error(responseData?.message);
            }
          },
          error: (_) => {
            this.toastr.error('Error occured');
          },
        });
    } else {
      this.toastr.warning('Please select valid user.');
    }
  }

  showImage(num: number) {
    if (num === 0) {
      this.selectedImage = this.getFrontViewImage(0);
    } else if (num === 1) {
      this.selectedImage = this.getFrontViewImage(1);
    } else if (num === 2) {
      this.selectedImage = this.getFrontViewImage(2);
    } else if (num === 3) {
      this.selectedImage = this.getBuildingMountedImage() || '';
    } else if (num === 4) {
      this.selectedImage = this.customer.billImageUrl;
    } else if (num === 5) {
      this.selectedImage = this.customer.cardImageUrl;
    } else if (num === 6) {
      this.selectedImage = this.customer.meterImageUrl;
    } else if (num === 7) {
      this.selectedImage = this.customer.meterNumberImageUrl;
    } else if (num === 8) {
      this.selectedImage = this.customer.backCardViewImageUrl;
    }
  }

  // selectFeeder() {
  //   this.customer.dtId = 0;
  //   this.getDtsForOptions(this.customer.feederId);
  // }

  closeRedirectModal() {
    this.closeDQModal?.nativeElement?.click();
  }
  cancelModal(event: any) {
    this.closeDQModal?.nativeElement?.click();
    this.routeToNextCustomerOrBuilding(event);
  }

  cancel(event: any) {
    if (!event) {
      return;
    }
    this.routeToNextCustomerOrBuilding(event);
  }

  updateStatus(event: any, type: string) {
    var status = AssetStatus.Pending;
    if (type === 'approve') 
    {
      status = AssetStatus.Complete;

      this.analyticsService.logCustomEvent('CUSTOMER DETAILS: Approve Customer', {
        customer_id: this.customer.id
      });
    }
    else {
      status = AssetStatus.Incomplete

      this.analyticsService.logCustomEvent('CUSTOMER DETAILS: Reject Customer', {
        customer_id: this.customer.id
      });
      
    }

    const obj = {
      customerId: this.customer.id,
      status: status,
      rejectionReason: this.rejectionReason,
    };
    this.customerService.updateStatus(obj).subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.toastr.success('Successfully');
          window.location.reload();
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }

  disableUpdateStatusButton() {
    if (this.customer.dataQualityId === 0) return false;
    else return true;
  }

  disableAuditReviewButton() {
    if (this.customer.auditedStatus === 'Audited') return true;
    else return false;
  }

  updateHistory(history: any) {
    this.history = history;
    this.customer.history = this.history;
    this.save();
  }

  openEditModal() {
    this.openUpdateDescriptionModal?.nativeElement?.click();
  }

  filterMobileUsers() {
    this.isDropdownOpened = true;
    this.tempAssignedUserId = 0;
    this.getMobileUsers();
  }

  selectFieldAgent(id: number) {
    this.isDropdownOpened = false;
    this.assignedUserId = id;
    this.tempAssignedUserId = id;
    var mobUser = this.mobileUsers.find((x) => x.id == id);
    this.filterText = mobUser!.fullName;
  }
  recallAssigned() {
    this.buildingService
      .recall(this.customer.building.id, RecallType.Assigned)
      .subscribe({
        next: (response) => {
          const responseData = response.body;
          if (responseData?.status === 200 || responseData?.status === 'OK') {
            this.toastr.success('Successfully recalled customer.');
            window.location.reload();
          } else {
            this.toastr.error(responseData?.message);
          }
        },
        error: (_) => {
          this.toastr.error('Error occured');
        },
      });
  }

  recallValidation() {
    this.customerService.recallDataQuality(this.customer.id).subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.toastr.success("Successfully recalled customer's Data Quality.");
          window.location.reload();
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }

  onModalAssignFade() {
    this.filterText = this.customer.building.assignedUser
      ? this.customer.building.assignedUser
      : '';
  }

  onModalFade() {
    this.currentRotation = 0;

    if (this.imgZoomFullContainer) {
      var displayValue = getComputedStyle(this.imgZoomFullContainer).display;

      if (displayValue == 'block') {
        this.imgElement.click();
      }
    }
  }

  rotateLeft() {
    this.currentRotation -= 90;
  }

  rotateRight() {
    this.currentRotation += 90;
  }

  onModalOpen() {
    this.renderer.setStyle(document.body, 'overflow', 'auto');
    this.renderer.setStyle(document.body, 'overflow-x', 'hidden');
    this.renderer.setStyle(document.body, 'padding-right', '0');
  }

  @HostListener('document:click', ['$event'])
  handleDocumentClick(event: MouseEvent) {
    const clickedInside =
      this.isDropdownOpened &&
      (this.dropdownContainer?.nativeElement?.contains(event.target) ||
        this.userFilter?.nativeElement?.contains(event.target));
    if (!clickedInside) {
      this.isDropdownOpened = false;
    }
  }

  routeToNextCustomerOrBuilding(data: NextDataQuality) {
    if (data.isBuilding) {
      this.router.navigateByUrl(`/buildings/edit/${data.id}`);
    } else {
      if (data.isOnboard) {
        this.router.navigateByUrl(`/customers/edit/${data.id}`);
      } else {
        this.router.navigateByUrl(`/customers/edit/${data.id}`).then(() => {
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        });
      }
    }
  }

  validateEdit() {
    if (!this.customer.dtId) {
      this.toastr.error('DT field is mandatory');
      return false;
    }
    return true;
  }

  setFieldsVisibility() {
    let notVisibleFields: any = localStorage.getItem('notVisibleFields');

    if (!notVisibleFields) {
        return;
    }

    notVisibleFields = JSON.parse(notVisibleFields);
    this.isGhanaPostFieldsVisible = notVisibleFields.find((el: any) => el === 'ghanaPostCode') ? false : true;
    this.isActualTariffFieldVisible = notVisibleFields.find((el: any) => el === 'actualTariff') ? false : true;
    this.isSPNFieldVisible = notVisibleFields.find((el: any) => el === 'spn') ? false : true;
    this.isServiceTypeFieldVisible = notVisibleFields.find((el: any) => el === 'serviceType') ? false : true;
    this.isMeterSlrnFieldVisible = notVisibleFields.find((el: any) => el === 'meterSlrn') ? false : true;
    this.isImageMeterFieldVisible = notVisibleFields.find((el: any) => el === 'imageMeter') ? false : true;
    this.isBackViewPictureFieldVisible = notVisibleFields.find((el: any) => el === 'backViewPicture') ? false : true;
    this.isRegularizationUploadVisible = notVisibleFields.find((el: any) => el === 'regularizationUpload') ? false : true;
  }


  getPermiseTypes(){
    this.customerService.getPermises().subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.permiseList = responseData.data;
          this.permiseOptions = [...new Set(this.permiseList.map(item => item.key))]
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });


  }

  mapUseOfPremise(useOfPremise:any){
    if(useOfPremise=='ReligiousHouse')
      return 'Religious House';
    return useOfPremise
  }
  getActivities(){
    this.customerService.getActivities().subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.activityList = responseData.data;
          this.activityOptions = [...new Set(this.activityList.map(item => item.key))]

        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }
  getOptions(){
    Object.values(Neighborhood)
    .filter((v) => !isNaN(Number(v)))
    .forEach((key) => {
      const optionObject: OptionObj = {
        key: Number(key),
        value: Neighborhood[key as number]
      };
      this.neighborhoodOptions.push(optionObject);
    });
    Object.values(CustomerType)
    .filter((v) => !isNaN(Number(v)))
    .forEach((key) => {
      const optionObject: OptionObj = {
        key: Number(key),
        value: CustomerType[key as number]
      };
      this.customerTypeOptions.push(optionObject);
    });
    Object.values(ClientType)
    .filter((v) =>v!=-1 && !isNaN(Number(v)))
    .forEach((key) => {
      const optionObject: OptionObj = {
        key: Number(key),
        value: ClientType[key as number]
      };
      this.clientTypeOptions.push(optionObject);
    });
    this.numberOfRoomsOptions = [...Array(50).keys()].map(x => ++x)

  }
  selectPermiseType() {
    this.customer.permiseCategory=undefined;
    this.permiseCategoryOptions = this.permiseList.filter(p=>p.key===this.customer.permiseType).map(function(item) {
      return item.value;
    })
  }
  selectActivity() {
   this.customer.subActivity = undefined;
    this.subactivityOptions = this.activityList.filter(p=>p.key==this.customer.activity).map(function(item) {
      return item.value;
    })
  }
  setDependant(){
    if( this.customer.activity != undefined)
    this.subactivityOptions = this.activityList.filter(p=>p.key==this.customer.activity).map(function(item) {
      return item.value;
    })
    if(this.customer.permiseType!=undefined)
    this.permiseCategoryOptions = this.permiseList.filter(p=>p.key===this.customer.permiseType).map(function(item) {
      return item.value;
    })
  }
}
