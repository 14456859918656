import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { MeterType, MeterTypeReadingStep } from 'src/app/models/meter.model';
import { PermissionsEnum } from 'src/app/models/role.model';
import { BPSAdminLevel } from 'src/app/models/user.model';
import { MeterService } from 'src/app/services/meter.service';
import { PermissionsService } from 'src/app/services/permissions.service';

@Component({
  selector: 'crud-meter-type',
  templateUrl: './crud-meter-type.component.html',
  styleUrls: ['./crud-meter-type.component.scss'],
})
export class CrudMeterTypeComponent implements OnInit {
  @Input() searchText!: string;
  @Input() utilityId: number = 0;
  @Input() bpsAdminLevel!: BPSAdminLevel;

  public PermissionsEnum = PermissionsEnum;
  public currentPage: number = 1;
  public pageSize: number = 5;
  public count: number = 0;

  meterTypes: MeterType[] = [];
  selectedMeterType: MeterType = new MeterType();
  actionLabel: string = '';
  readingStepsVisible: boolean = false;
  imageRemoved: boolean = false;
  // imageUrl?: string;
  @ViewChild('closeAddModal') closeAddModal: ElementRef;
  @ViewChild('closeDeleteModal') closeDeleteModal: ElementRef;

  constructor(
    private meterService: MeterService,
    public permissionsService: PermissionsService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    if (this.utilityId != 0) {
      this.reloadTable();
    }
  }

  addNew() {
    this.selectedMeterType = new MeterType();
    this.actionLabel = 'New';
  }

  selectMeterType(meterType: MeterType) {
    this.selectedMeterType = { ...meterType };
    this.actionLabel = 'Edit';
  }

  pageChange(value: any) {
    this.currentPage = value;
    this.reloadTable(this.searchText);
  }

  reloadTable(searchText?: string, page: any = null) {
    if (page) {
      this.currentPage = page;
    }

    let dataIn = {
      pageInfo: {
        page: this.currentPage,
        pageSize: this.pageSize,
      },
      filterParams: {
        searchValue: searchText,
      },
      utilityId: this.utilityId,
    };
    this.getAllMeterTypes(dataIn);
  }

  filter() {
    this.currentPage = 1;
    let dataIn = {
      pageInfo: {
        page: this.currentPage,
        pageSize: this.pageSize,
      },
      filterParams: {
        SearchValue: this.searchText,
      },
      utilityId: this.utilityId,
    };
    this.getAllMeterTypes(dataIn);
  }

  private getAllMeterTypes(dataIn: any) {
    this.meterService.getAllMeterTypes(dataIn).subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.meterTypes = responseData.data.data;
          this.count = responseData.data.count;
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }

  save() {
    this.selectedMeterType.utilityId = this.utilityId;
    if (this.imageRemoved){
      this.selectedMeterType.imageUrl = '';
      this.selectedMeterType.imageFile = undefined;
    }

    this.meterService.saveMeterType(this.selectedMeterType).subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.closeAddModal?.nativeElement?.click();
          this.toastr.success(responseData?.message);
          this.readingStepsVisible = false;
          this.imageRemoved = false;
          if (this.selectedMeterType.id === 0) {
            this.pageChange(1);
            return;
          }
          this.reloadTable(this.searchText);
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }

  deleteMeterType() {
    this.meterService.deleteMeterType(this.selectedMeterType.id).subscribe({
      next: (response) => {
        const responseData = response.body;
        this.closeDeleteModal?.nativeElement?.click();
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.pageChange(1);
          this.toastr.success(responseData?.message);
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }

  addReadingSteps(event: Event, stepOrder: number) {
    event.preventDefault();
    event.stopPropagation();
    if (stepOrder === 1) {
      this.selectedMeterType.readingSteps = [];
      this.readingStepsVisible = true;
    } else if (
      !this.selectedMeterType.readingSteps!.find(
        (x) => x.stepOrder == stepOrder - 1
      )?.description
    ) {
      this.toastr.warning('Please configure the previous step first');
      return;
    }

    var step = new MeterTypeReadingStep();
    step.stepOrder = stepOrder;
    this.selectedMeterType.readingSteps?.push(step);
  }

  showReadingSteps(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.readingStepsVisible = true;
  }

  onModalFade() {
    var type = this.meterTypes.find(x => x.id == this.selectedMeterType.id);
    if (type?.readingSteps)
      type!.readingSteps = type!.readingSteps?.filter(x => x.description); 
    this.selectedMeterType = new MeterType();
    this.readingStepsVisible = false;
    this.imageRemoved = false;
  }

  onFileSelected($event: any): void {
    const input = $event.target as HTMLInputElement;
    this.imageRemoved = false;
    if (input.files && input.files[0]) {
      const file = input.files[0];
      if (file.size > 2097152){
        this.toastr.warning("Please choose a smaller image. The maximum allowed size is 2MB");
      }
      else {
        this.selectedMeterType.imageFile = file;

        const reader = new FileReader();
  
        reader.onload = (e: any) => {
          this.selectedMeterType.imageUrl = e.target.result;
        };
  
        reader.readAsDataURL(file);
      }
      
    }
  }

  removeImage(event: Event){
    event.preventDefault();
    event.stopPropagation();
    this.imageRemoved = true;
  }

  removeStep(event: Event, position: number){
    event.preventDefault();
    event.stopPropagation();
    this.selectedMeterType.readingSteps = this.selectedMeterType.readingSteps?.filter(x => x.stepOrder != position);
    this.selectedMeterType.readingSteps?.forEach(element => {
      element.stepOrder = element.stepOrder > position ? element.stepOrder - 1 : element.stepOrder;
    });
  }
}
