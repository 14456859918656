import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HistoryOutput } from 'src/app/models/history.model';
import { EntityType, ImageType } from 'src/app/models/image.model';
import {
  EditPole,
  PoleSave,
  PolesBuilding,
  PolesCustomers,
} from 'src/app/models/pole.model';
import { PermissionsEnum } from 'src/app/models/role.model';
import { User } from 'src/app/models/user.model';
import { PoleService } from 'src/app/services/pole.service';
import { UserService } from 'src/app/services/user.service';
import { TranslationService } from 'src/app/services/translation.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { AssetStatusEnum } from 'src/app/models/assetStatus.model';
import { OptionObj } from 'src/app/models/util.model';

@Component({
  selector: 'app-edit-poles',
  templateUrl: './edit-poles.component.html',
  styleUrls: ['./edit-poles.component.scss'],
})
export class EditPolesComponent implements OnInit {
  @ViewChild('dropdownContainer') dropdownContainer: ElementRef;
  @ViewChild('userFilterInput') userFilter: ElementRef;
  @ViewChild('closeAudit') auditReviewModal: ElementRef;

  AssetAssignType = AssetStatusEnum;
  assetAssignType: AssetStatusEnum;

  public PermissionsEnum = PermissionsEnum;
  editEnabled: boolean = false;
  id: string = '';
  selectedPole: EditPole = new EditPole();
  mobileUsers: User[] = [];

  longitude: number = 0;
  latitude: number = 0;

  poleImage: File | null;
  selectedImage: string = '';
  ImageType = ImageType;
  EntityType = EntityType;

  currentBuildingPage = 0;
  currentBuilding: any;
  countOfBuilding = 0;
  buName: string = this.translationService.getByKeyFromStorage('BU');
  utName: string = this.translationService.getByKeyFromStorage('UT');

  currentCustomerPage = 0;
  currentCustomer = new PolesCustomers();
  countOfCustomer = 0;
  poleTypesOptions: OptionObj[] = [];
  history: HistoryOutput = new HistoryOutput();
  filterText: string = '';
  isDropdownOpened: boolean = false;
  filteredMobileUsers: User[] = [];
  tempAssignedUserId: number = 0;
  imgElement: any;
  imgZoomFullContainer: any;
  currentRotation: number = 0;
  showRecallMenu: boolean = false;
  recallAssignedAllowed: boolean = false;
  recallValidatedAllowed: boolean = false;

  @ViewChild('closeModal') closeModal: ElementRef;
  @ViewChild('openUpdateDescriptionModal')
  openUpdateDescriptionModal: ElementRef;
  @ViewChild('closeUpdateHistoryModal') closeUpdateHistoryModal: ElementRef;
  isGhanaPostFieldsVisible: boolean = true;
  isMapVisible: boolean = false;
  modalRejectionReason?: string = '';
  isDataQuality: boolean = false;

  constructor(
    private poleService: PoleService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private userService: UserService,
    public permissionsService: PermissionsService,
    private translationService: TranslationService,
    private el: ElementRef,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    this.setFieldsVisibility();
    this.id = this.route.snapshot.paramMap.get('id') || '';
    this.isDataQuality = this.route.snapshot.url[0].path == 'data-quality';

    this.imgElement = this.el?.nativeElement?.querySelector(
      '.ngxImageZoomThumbnail'
    );
    this.imgZoomFullContainer =
      this.el?.nativeElement?.querySelector('.ngxImageZoomFull');
    this.getSelectedPole(parseInt(this.id));
    this.getPoleTypesForOptions();
  }

  setModalType(type: AssetStatusEnum) {
    this.assetAssignType = type;
  }

  getPoleTypesForOptions() {
    this.poleService.getPoleTypesForOptions().subscribe(
      (response) => {
        this.poleTypesOptions = response.data;
      },
      (error) => {
        this.toastr.error('An error occurred.');
      }
    );
  }

  assignTask(): void {
    if (
      this.mobileUsers.filter((x) => x.fullName == this.filterText.trim())
        .length > 0
    ) {
      this.selectedPole.assignedUserId = this.mobileUsers.filter(
        (x) => x.fullName == this.filterText.trim()
      )[0].id;
      this.tempAssignedUserId = this.selectedPole.assignedUserId;
    } else {
      this.tempAssignedUserId = 0;
    }
    if (this.tempAssignedUserId != 0) {
      this.poleService
        .assignPole(
          this.selectedPole.id,
          this.selectedPole.assignedUserId,
          this.assetAssignType,
          this.isDataQuality
        )
        .subscribe({
          next: (response) => {
            const responseData = response.body;
            if (responseData?.status === 200 || responseData?.status === 'OK') {
              this.closeModal?.nativeElement?.click();
              this.toastr.success('Successfully assigned user');
              window.location.reload();
            } else {
              this.toastr.error(responseData?.message);
            }
          },
          error: (_) => {
            this.toastr.error('Error occured');
          },
        });
    } else {
      this.toastr.warning('Please select valid user.');
    }
  }

  getSelectedPole(id: number) {
    this.poleService.getById(id).subscribe({
      next: (data) => {
        this.selectedPole = new EditPole(data?.body?.data);
        this.longitude = this.selectedPole.longitude;
        this.latitude = this.selectedPole.latitude;
        this.countOfBuilding =
          this.selectedPole.connectedBuildings?.length ?? 0;
        this.filterText = this.selectedPole.assignedUser;
        this.tempAssignedUserId = this.selectedPole.assignedUserId;
        if (this.countOfBuilding != 0) {
          this.currentBuilding = this.selectedPole.connectedBuildings[0];
          if (this.currentBuilding.connectedCustomers.length != 0) {
            this.currentCustomer = this.currentBuilding.connectedCustomers[0];
            this.countOfCustomer =
              this.currentBuilding.connectedCustomers.length;
          }
        }

        if (
          this.permissionsService.isPoleRecallAssignedAllowed &&
          this.selectedPole.status === 'Assigned'
        ) {
          this.recallAssignedAllowed = true;
        }

        if (
          this.permissionsService.isPoleRecallValidatedAllowed &&
          this.selectedPole.dataQualityId !== 0
        ) {
          this.recallValidatedAllowed = true;
        }

        if (
          this.selectedPole.dataValidationReview != null &&
          this.selectedPole.dataValidationReview.length > 0
        ) {
          this.modalRejectionReason =
            this.selectedPole.dataValidationReview.find(
              (x) => x.key == 'Reason for rejection'
            )?.value;
        }
      },
      error: (err) => {
        this.toastr.error('An error ocurred.');
      },
    });
  }

  edit() {
    this.editEnabled = true;
  }

  previousBuilding() {
    this.currentBuildingPage--;

    if (this.selectedPole.connectedBuildings.length == 0) {
      this.currentBuilding = new PolesBuilding();
      this.currentBuildingPage = 0;
      return;
    } else if (this.currentBuildingPage < 0) {
      this.currentBuildingPage = 0;
    }

    this.currentBuilding =
      this.selectedPole.connectedBuildings[this.currentBuildingPage];
    this.currentCustomerPage = 0;
    if (this.currentBuilding.connectedCustomers.length != 0) {
      this.currentCustomer =
        this.currentBuilding.connectedCustomers[this.currentCustomerPage];
    } else {
      this.currentCustomer = new PolesCustomers();
    }
  }
  disableAuditReviewButton() {
    if (this.selectedPole.auditedStatus === 'Audited') return true;
    else return false;
  }
  cancel() {
    this.auditReviewModal?.nativeElement?.click();
  }

  nextBuilding() {
    this.currentBuildingPage++;
    this.currentBuilding =
      this.selectedPole.connectedBuildings[this.currentBuildingPage];

    if (this.selectedPole.connectedBuildings.length == 0) {
      this.currentBuilding = new PolesBuilding();
      this.currentBuildingPage = 0;

      return;
    } else if (
      this.currentBuildingPage >
      this.selectedPole.connectedBuildings.length - 1
    ) {
      this.currentBuildingPage =
        this.selectedPole.connectedBuildings.length - 1;
    }

    this.currentBuilding =
      this.selectedPole.connectedBuildings[this.currentBuildingPage];
    this.currentCustomerPage = 0;
    if (this.currentBuilding.connectedCustomers.length != 0) {
      this.currentCustomer =
        this.currentBuilding.connectedCustomers[this.currentCustomerPage];
    } else {
      this.currentCustomer = new PolesCustomers();
    }
  }

  previousCustomer() {
    this.currentCustomerPage--;

    if (this.currentBuilding.connectedCustomers.length == 0) {
      this.currentCustomer = new PolesCustomers();
      this.currentCustomerPage = 0;
      return;
    } else if (this.currentCustomerPage < 0) {
      this.currentCustomerPage = 0;
    }

    this.currentCustomer =
      this.currentBuilding.connectedCustomers[this.currentCustomerPage];
  }

  nextCustomer() {
    this.currentCustomerPage++;

    if (this.currentBuilding.connectedCustomers.length == 0) {
      this.currentCustomer = new PolesCustomers();
      this.currentCustomerPage = 0;
      return;
    } else if (
      this.currentCustomerPage >
      this.currentBuilding.connectedCustomers.length - 1
    ) {
      this.currentCustomerPage =
        this.currentBuilding.connectedCustomers.length - 1;
    }

    this.currentCustomer =
      this.currentBuilding.connectedCustomers[this.currentCustomerPage];
  }

  selectOption(e: any) {
    this.selectedPole.assignedUserId = +e.target.value;
  }

  updateHistory(history: any) {
    this.history = history;
    this.selectedPole.history = this.history;

    this.save();
  }

  validateInput(event: Event) {
    if (!this.selectedPole.poleNumber) {
      this.toastr.error('LT Pole number can not be empty!');
      return;
    }
    if (!this.selectedPole.upriserNumber) {
      this.toastr.error('Upriser number can not be empty!');
      return;
    }
    if (!this.selectedPole.poleType) {
      this.toastr.error('Type of pole can not be empty!');
      return;
    }
    if (!this.selectedPole.address) {
      this.toastr.error('LT Pole address can not be empty!');
      return;
    }

    this.openUpdateDescriptionModal?.nativeElement?.click();
  }

  save() {
    let saveModel = new PoleSave();
    saveModel.upriserNumber = this.selectedPole.upriserNumber;
    saveModel.poleNumber = this.selectedPole.poleNumber;
    saveModel.poleType = this.selectedPole.poleType;
    saveModel.address = this.selectedPole.address;
    saveModel.id = this.selectedPole.id;
    saveModel.history = this.selectedPole.history;

    this.poleService.addOrUpdatePole(saveModel).subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.editEnabled = false;
          this.toastr.success('Successfully edited pole');
          window.location.reload();
          this.openUpdateDescriptionModal?.nativeElement?.click();
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }

  getMobileUsers() {
    this.userService
      .getMobileUsers(this.selectedPole.districtId, this.filterText)
      .subscribe({
        next: (response) => {
          const responseData = response.body;
          if (responseData?.status === 200 || responseData?.status === 'OK') {
            this.mobileUsers = responseData.data;
            if (this.mobileUsers.length == 0) {
              this.isDropdownOpened = false;
            } else {
              this.isDropdownOpened = true;
            }
          } else {
            this.toastr.error(responseData?.message);
          }
        },
        error: (_) => {
          this.toastr.error('Error occured');
        },
      });
  }

  getBuildingMountedImage() {
    return this.selectedPole.images?.find((x) => x.imageType === 'MountedTag')
      ?.url;
  }

  getLTPoleLeftImage() {
    return this.selectedPole.images?.find((x) => x.imageType === 'LTPoleLeft')
      ?.url;
  }

  openDropdown() {
    this.isDropdownOpened = true;
  }
  getLTPoleRightImage() {
    return this.selectedPole.images?.find((x) => x.imageType === 'LTPoleRight')
      ?.url;
  }
  disableUpdateStatusButton() {
    if (this.selectedPole.dataQualityId === 0) return false;
    else return true;
  }
  fileBrowserHandler($event: any, type: ImageType) {
    this.poleImage = $event.target.files.item(0);
    const obj = {
      imageType: type,
      poleId: this.selectedPole.id,
      guid: this.selectedPole.guid, 
    };
    if (!this.poleImage) {
      this.toastr.error('Image data is missing');
      return;
    }
    this.poleService.uploadPicture(obj, this.poleImage).subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.selectedPole.images.push(responseData.data);
          this.toastr.success('Successfully added image');
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }

  showImage(num: number) {
    if (num === 1) {
      this.selectedImage = this.getLTPoleRightImage() || '';
    } else if (num === 2) {
      this.selectedImage = this.getLTPoleLeftImage() || '';
    } else if (num === 3) {
      this.selectedImage = this.getBuildingMountedImage() || '';
    }
  }

  selectFieldAgent(id: number) {
    this.isDropdownOpened = false;
    this.selectedPole.assignedUserId = id;
    this.tempAssignedUserId = id;
    var mobUser = this.mobileUsers.find((x) => x.id == id);
    this.filterText = mobUser!.fullName;
  }

  filterMobileUsers() {
    this.isDropdownOpened = true;
    this.tempAssignedUserId = 0;
    this.getMobileUsers();
  }
  recall() {
    this.poleService.recall(parseInt(this.id)).subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.toastr.success('Successfully recalled pole.');
          window.location.reload();
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }

  onModalAssignFade() {
    this.filterText = this.selectedPole.assignedUser;
  }

  onModalFade() {
    this.currentRotation = 0;

    if (this.imgZoomFullContainer) {
      var displayValue = getComputedStyle(this.imgZoomFullContainer).display;

      if (displayValue == 'block') {
        this.imgElement.click();
      }
    }
  }

  rotateLeft() {
    this.currentRotation -= 90;
  }

  rotateRight() {
    this.currentRotation += 90;
  }

  onModalOpen() {
    this.renderer.setStyle(document.body, 'overflow', 'auto');
    this.renderer.setStyle(document.body, 'overflow-x', 'hidden');
    this.renderer.setStyle(document.body, 'padding-right', '0');
  }

  @HostListener('document:click', ['$event'])
  handleDocumentClick(event: MouseEvent) {
    const clickedInside =
      this.isDropdownOpened &&
      (this.dropdownContainer?.nativeElement?.contains(event.target) ||
        this.userFilter?.nativeElement?.contains(event.target));
    if (!clickedInside) {
      this.isDropdownOpened = false;
    }
  }

  setFieldsVisibility() {
    let notVisibleFields: any = localStorage.getItem('notVisibleFields');

    if (!notVisibleFields) {
      return;
    }

    notVisibleFields = JSON.parse(notVisibleFields);
    this.isGhanaPostFieldsVisible = notVisibleFields.find(
      (el: any) => el === 'ghanaPostCode'
    )
      ? false
      : true;
  }
}
