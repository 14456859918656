import { Component, ElementRef, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Area } from 'src/app/models/area.model';
import { ChecklistItem } from 'src/app/models/checklist-item.model';
import { CustomerBillingAccount } from 'src/app/models/customer.model';
import { Region } from 'src/app/models/region.model';
import { PermissionsEnum } from 'src/app/models/role.model';
import { Tariff } from 'src/app/models/tariff.model';
import { BPSAdminLevel } from 'src/app/models/user.model';
import { OptionObj } from 'src/app/models/util.model';
import { AreaService } from 'src/app/services/area.service';
import { BillingAccountService } from 'src/app/services/billing-account.service';
import { CustomerService } from 'src/app/services/customer.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { RegionService } from 'src/app/services/region.service';
import { TariffService } from 'src/app/services/tariff.service';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'crud-billing-account',
  templateUrl: './crud-billing-account.component.html',
  styleUrls: ['./crud-billing-account.component.scss']
})
export class CrudBillingAccountComponent implements OnInit {

  constructor(private router: Router, private toastr: ToastrService, public permissionsService: PermissionsService,
    private customerService : CustomerService, private regionService: RegionService, private billingAccountService : BillingAccountService,
    private areaService: AreaService, private tariffService: TariffService, private translationService: TranslationService ) { }

 @Input() searchText!: string;
 @Input() searchType!: string;

 @Input() utilityId: number = 0;
 @Input() bpsAdminLevel!: BPSAdminLevel;

 public PermissionsEnum = PermissionsEnum;
 public currentPage: number = 1;
 public pageSize: number = 5;
 public count: number = 0;
 public showUpload : boolean = false;
 regions: Region[] = [];
 allAreas: Area[] = [];
 areas: Area[] = [];
 billingAccounts: CustomerBillingAccount[] = [];
 actionLabel: string = '';
 buName: string = this.translationService.getByKeyFromStorage('BU');
 utName: string = this.translationService.getByKeyFromStorage('UT');
 contractNumber: string = this.translationService.getByKeyFromStorage('Contract number');
 selectedAreas: number[] = [];
 selectedRegions: number[] = [];
 billAccForDeleteId: number = 0;

 @ViewChild('closeAddModal') closeAddModal: ElementRef;
 @ViewChild('closeDeleteModal') closeDeleteModal: ElementRef;
 
 tariff: number = 0;
 tariffs: Tariff[] = [];

 accountStatus?: number;
 accountStatusOptions = [
   { name: 'Active', value: 1 },
   { name: 'Inactive', value: 2 }
 ];
 sltStatus?: number;

 sltOptions: OptionObj[] = [];
 
 clicked:boolean = false;

 selectedFilters: ChecklistItem[] = [];
 isGhanaPostFieldsVisible: boolean = true;

 ngOnInit(): void {
   if(this.utilityId != 0) {
     this.getSltStatuses();
     this.setFilters();
     this.reloadTable();
   }
 }

 selectBillingAccount(id: number) {
   this.router.navigate([`/customers/edit-billing-account/${id}/${this.utilityId}`])
   this.setFilterStorage();
 }

 pageChange(value: any) {
   this.currentPage = value;
   this.reloadTable();
 }

 setBillingAccountForDeleteId(id : number){
   this.billAccForDeleteId = id;
 }

 reloadTable(page: any = null) {
   if(page) {
     this.currentPage = page;
   }

   let dataIn = {
     pageInfo:{
       page: this.currentPage,
       pageSize: this.pageSize,
       count: this.count
     },
     filterParams: this.getFilterObject()
   };
   this.getAllBillingAccounts(dataIn);
 }

 getFilterObject(){
   let obj = {
     searchType: this.searchType ? this.searchType : null,
     search: this.searchText,
     tariffId: this.tariff != 0 ? this.tariff : null,
     accountStatus: this.accountStatus ? this.accountStatus : null,
     sltStatusId: this.sltStatus ? this.sltStatus : null,
     selectedAreas: this.selectedAreas.length > 0 ? this.selectedAreas : null,
     selectedRegions: this.selectedRegions.length > 0 ? this.selectedRegions : null,
   };

   return obj;
 }
 setFilterStorage() {
   let obj = {
     pageInfo: {
       page: this.currentPage,
       pageSize: this.pageSize,
       count: this.count
     },
     filterParams: this.getFilterObject(),
   };
   localStorage.setItem(
     'filter',
     JSON.stringify({ type: 'BillingAccount', filter: obj })
   );
 }

 filter() {
   this.currentPage = 1;
   let dataIn = {
     pageInfo:{
       page: this.currentPage,
       pageSize: this.pageSize,
       count: this.count
     },
     filterParams: {
       search: this.searchText, 
       searchType : this.searchType
     },
   };
   this.getAllBillingAccounts(dataIn);
 }

 private getAllBillingAccounts(dataIn: any) {
   this.billingAccountService.getCustomerBillingAccountInformation(dataIn).subscribe({
     next: response => {
       const responseData = response.body;
       if(responseData?.status === 200 || responseData?.status === "OK"){
         this.billingAccounts = responseData.data.data;
         console.log(this.billingAccounts);
         this.count = responseData.data.count ?? this.count;
         this.createFilterCards();
       } else {
         this.toastr.error(responseData?.message);
       }
     },
     error: _ => {
       this.toastr.error("Error occured");
     }
   });
 }


 deleteBillingAccount() {
   this.billingAccountService.delete(this.billAccForDeleteId).subscribe({
     next: response => {
       const responseData = response.body;
       this.closeDeleteModal?.nativeElement?.click();
       if(responseData?.status === 200 || responseData?.status === "OK"){
         this.pageChange(1);
         this.toastr.success(responseData?.message);
       } else {
         this.toastr.error(responseData?.message);
       }
     },
     error: _ => {
       this.toastr.error("Error occured");
     }
   });
 }

 private getRegions() {
   this.regionService.getAllForSelectNoUser(this.utilityId).subscribe({
     next: response => {
       const responseData = response.body;
       if(responseData?.status === 200 || responseData?.status === "OK"){
         this.regions = responseData.data;
        this.setCheckedRegions();
       }else if (response?.status == 204) {
       } else {
         this.toastr.error(responseData?.message);
       }
     },
     error: _ => {
         this.toastr.error("Error occured");
       }
   });
 }

 private getAreas() {
   this.areaService.getAllForOptions(this.utilityId).subscribe({
     next: response => {
       const responseData = response.body;
       if (responseData?.status === 200 || responseData?.status === "OK") {
        this.allAreas = responseData.data;
        this.areas = this.allAreas.filter((area) =>
          this.regions.some(
            (region) => region.id == area.regionId && region.checked
          )
        );
         this.setCheckedAreas()
       }else if (response?.status == 204) {
       } else {
         this.toastr.error(responseData?.message);
       }
     },
     error: _ => {
       this.toastr.error("Error occured");
     }
   });
 }

 getTariffs(){
   this.tariffService.getAllForSelect(this.utilityId).subscribe({
     next: response => {
       const responseData = response.body;
       if(responseData?.status === 200 || responseData?.status === "OK"){
         this.tariffs = responseData.data;
       } else {
         this.toastr.error(responseData?.message);
       }
     },
     error: _ => { 
       this.toastr.error("Error occured") 
      }
   });
 }

 getSltStatuses() {
   this.customerService.getSltStatusesForOptions().subscribe({
     next: (response) => {
       const responseData = response.body;
       if (responseData?.status === 200 || responseData?.status === 'OK') {
         this.sltOptions = responseData.data;
       } else if (response?.status == 204) {
       } else {
         this.toastr.error(responseData?.message);
       }
     },
     error: (_) => {
       this.toastr.error('Error occured');
     },
   });
 }

 selectTariff(e: any) {
   this.tariff = +e.target.value;
   this.createFilterCards();
 }

 selectStatus(e: any) {
   if(e.target.value != '') {
     this.accountStatus = e.target.value;
   }
   else {
     this.accountStatus = undefined;
   }
   this.createFilterCards();  
 }

 selectSltStatus(e: any) {
   if (e.target.value != '') {
     this.sltStatus = parseInt(e.target.value);
   } else {
     this.sltStatus = undefined;
   }
   this.createFilterCards();
 }

 selectRegion(e: any) {
  //list of areas for selected regions
  this.areas = this.allAreas.filter((area) =>
    this.regions.some(
      (region) => region.id == area.regionId && region.checked
    )
  );
  this.allAreas.forEach((area) => {
    if (!this.areas.some((a) => a.id === area.id)) {
      area.checked = false; //uncheck areas for unchecked regions
    }
  });
  this.createFilterCards();
 }

 selectArea(e: any) {
   this.createFilterCards();
 }

 removeFilter(item: ChecklistItem) {
   if(item.property == this.buName) {
     this.regions.forEach(region => {
       if (region.name === item.selectedValue) {
         region.checked = false;
       }
     });
     this.clicked = !this.clicked;
   }
   else if(item.property == this.utName) {
     this.areas.forEach(area => {
       if (area.name === item.selectedValue) {
         area.checked = false;
       }
     });
     this.clicked = !this.clicked;
   }
   else if(item.property == 'Tariff') {
     this.tariff = 0;
   }
   else if(item.property == 'Account Status') {
     this.accountStatus = undefined;
   }
   else if(item.property == 'Slt status') {
     this.sltStatus = undefined;
   }

   this.createFilterCards();
   if(this.selectedFilters.length == 0) {
     this.reloadTable(1);
   }
 }

 resetFilter(e: any) {
   this.selectedFilters = [];

   this.regions.forEach(region => {
     region.checked = false;
   });

   this.areas.forEach(area => {
     area.checked = false;
   });
   this.selectedAreas = [];
   this.selectedRegions = [];

   this.tariff = 0;
   this.accountStatus = undefined;
   this.sltStatus = undefined;

   this.clicked = !this.clicked;

   this.reloadTable(1);
 }

 createFilterCards() {
    this.selectedFilters = [];

    let selectedAreas = this.areas.filter(x => x.checked);
    this.selectedAreas = this.areas.filter(x => x.checked).map(x => x.id);
    this.selectedRegions = this.regions.filter(x => x.checked).map(x => x.id);
    //areas
    for(var i = 0; i < selectedAreas.length; i++) {
      this.selectedFilters.push(new ChecklistItem(this.utName, selectedAreas[i].name));
    }

    //tariff
    if(this.tariff != 0) {
      let tariffName = this.tariffs.filter(x => x.id == this.tariff)[0].name;
      this.selectedFilters.push(new ChecklistItem('Tariff', tariffName));
    }

    //slt
    if(this.sltStatus) {
      var sltStatusName = this.sltOptions.find(x => x.key == this.sltStatus)!.value;
      this.selectedFilters.push(new ChecklistItem('Slt status', sltStatusName));
    }

   //status
    if(this.accountStatus) {
      var accountStatusName = this.accountStatusOptions.find(x => x.value == this.accountStatus)!.name;
      this.selectedFilters.push(new ChecklistItem('Account status', accountStatusName));
    }
 }  
 stopPropagation(event: Event) {
   event.stopPropagation();
 }
 setFilters() {
  var filter = localStorage.getItem('filter');
  if (!filter) return;
  var filterObject = JSON.parse(filter);
  if (filterObject.type != 'CustomerCrud' && filterObject.type != 'BillingAccount') return;
  this.currentPage = filterObject.filter.pageInfo.page;
  this.pageSize = filterObject.filter.pageInfo.pageSize;
  this.count = filterObject.filter.pageInfo.count;
  this.searchText = filterObject.filter.filterParams.search;
  this.tariff = filterObject.filter.filterParams.tariffId
    ? filterObject.filter.filterParams.tariffId
    : 0;
  this.sltStatus = filterObject.filter.filterParams.sltStatusId
    ? filterObject.filter.filterParams.sltStatusId
    : undefined; 
  this.accountStatus = filterObject.filter.filterParams.accountStatus
    ? filterObject.filter.filterParams.accountStatus
    : undefined; 
  this.selectedAreas = filterObject.filter.filterParams.selectedAreas
    ? filterObject.filter.filterParams.selectedAreas
    : [];
  this.selectedRegions = filterObject.filter.filterParams.selectedRegions
    ? filterObject.filter.filterParams.selectedRegions
    : [];
 }

 setCheckedAreas() {
   var filter = localStorage.getItem('filter');
   if (!filter) return;
   var filterObject = JSON.parse(filter);
   if (filterObject.type != 'CustomerCrud' && filterObject.type != 'BillingAccount') return;
   if (filterObject.filter.filterParams.selectedAreas) {
     this.selectedAreas = filterObject.filter.filterParams.selectedAreas;
     this.areas = this.allAreas.filter((area) =>
      this.regions.some(
        (region) => region.id == area.regionId && region.checked
      )
    );
    this.areas.forEach((element) => {
      if (this.selectedAreas.includes(element.id)) element.checked = true;
      else element.checked = false;
    });
   }
 }
 setCheckedRegions() {
   var filter = localStorage.getItem('filter');
   if (!filter) return;
   var filterObject = JSON.parse(filter);
   if (filterObject.type != 'CustomerCrud' && filterObject.type != 'BillingAccount') return;
   if (filterObject.filter.filterParams.selectedRegions) {
     this.selectedRegions = filterObject.filter.filterParams.selectedRegions;
     this.regions.forEach((element) => {
       if (this.selectedRegions.includes(element.id)) element.checked = true;
       else element.checked = false;
     });
   }
 }
 ngOnChanges(changes: SimpleChanges) {
   if (changes["utilityId"]) {
     if(this.utilityId != 0) {
       this.getRegions();
       this.getAreas();
       this.getTariffs();
     }
   }
 }

  applyFilter(item: ChecklistItem) {
    this.reloadTable(1);
  }
}
