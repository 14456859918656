<div class="container-fluid">
  <div class="regular-tabs">
    <div class="d-flex justify-content-start switch-toggle transparent">
      <a class="separated-toggle-item regularization-separated-toggle-item"
        [ngClass]="selectedTab == tabs.Regularized ? 'active' : ''" (click)="changeTab(tabs.Regularized)">
        <div>
          <span class="bubble">{{ stats.regularized ?? 0 | number }}</span> Regularized
        </div>
      </a>
      <a class="separated-toggle-item regularization-separated-toggle-item"
        [ngClass]="selectedTab == tabs.Queue ? 'active' : ''" (click)="changeTab(tabs.Queue)">
        <div>
          <span class="bubble">{{ stats.queued ?? 0 | number }}</span> In Queue
        </div>
      </a>
      <a class="separated-toggle-item regularization-separated-toggle-item"
        [ngClass]="selectedTab == tabs.Failed ? 'active' : ''" (click)="changeTab(tabs.Failed)">
        <div>
          <span class="bubble">{{ stats.failed ?? 0 | number }}</span> Failed
        </div>
      </a>

    </div>
  </div>
  <div class="green-table">
    <div class="table-action-wrapper d-flex align-items-center justify-content-between">
      <div class="d-flex">
        <div class="search-input">
          <img src="../../assets/icons/search.svg" alt="Search icon" (click)="search()" />
          <input type="text" placeholder="Search" [(ngModel)]="searchText" (keydown.enter)="search()"
            (ngModelChange)="this.searchTextUpdate.next($event)" />
        </div>
        <div class="search-type-picker">
          <div class="dropdown" (click)="showSelectSearchType = !showSelectSearchType" click-stop-propagation>
            <span class="label">By:</span>
            <span>{{ selectedSearchTypeDisplay }}</span>
            <i class="arrow-custom --small down"></i>
          </div>
          <div class="dropdown-opened" *ngIf="showSelectSearchType">
            <div (click)="
                  selectSearchType(searchType)
                " *ngFor="let searchType of searchByOptions">
              <div>{{ searchType.value }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="ml-auto d-flex">
        <div>
          <div class="d-flex">
            <div class="datetype-picker">
              <div class="dropdown" (click)="showSelectDateType = !showSelectDateType" click-stop-propagation>
                <span>Date type:</span>
                <span>{{ selectedDateTypeDisplay }}</span>
                <i class="arrow-custom --small down"></i>
              </div>
              <div class="dropdown-opened" *ngIf="showSelectDateType">
                <ng-container *ngFor="let dateType of dateTypes">
                  <div *ngIf="!dateType.hidden" (click)="
                      selectDateType(dateType); (datesValid() == true && (dateTo || dateFrom)) && search()">
                    <div>{{ dateType.value }}</div>
                  </div>
                </ng-container>

              </div>
            </div>
            <div class="datepicker-box space-right">
              <label for="dateFrom"> From: </label>
              <input #fromPicker onclick="this.showPicker()" placeholder="dd/mm/yyyy" type="date" id="dateFrom"
                name="dateFrom" [ngClass]="
                    !datesValid()
                      ? 'datepicker-input-error'
                      : 'datepicker-input'
                  " [(ngModel)]="dateFrom" (change)="datesValid() == true && search()" />
              <i class="arrow-custom --small down"></i>
            </div>

            <div class="datepicker-box space-right">
              <label for="dateTo"> To: </label>
              <input onclick="this.showPicker()" type="date" id="dateTo" name="dateTo" [ngClass]="
                    !datesValid()
                      ? 'datepicker-input-error'
                      : 'datepicker-input'
                  " [(ngModel)]="dateTo" (change)="datesValid() == true && search()" />
              <i class="arrow-custom --small down"></i>
            </div>
          </div>
        </div>
        <div class="sort-by-root" (click)="showSortBy = !showSortBy" click-stop-propagation>
          <div class="dropdown">
            <span>Sort by:</span>
            <span>{{ sortByLabelValue.label }}</span>
            <i class="arrow-custom --small down"></i>
          </div>
          <div class="dropdown-opened" *ngIf="showSortBy">
            <div (click)="sortBy(sortByOptions.NEWEST)">
              <div>Newest</div>
            </div>
            <div (click)="sortBy(sortByOptions.SLRN_ASC)">
              <div>SLRN</div>
              <img class="arrow-up" src="../../../assets/icons/arrow.svg" alt="Arrow icon" />
            </div>
            <div (click)="sortBy(sortByOptions.SLRN_DESC)">
              <div>SLRN</div>
              <img src="../../../assets/icons/arrow.svg" alt="Arrow icon" />
            </div>
            <div (click)="sortBy(sortByOptions.ACCNO_ASC)">
              <div>AccNo.</div>
              <img class="arrow-up" src="../../../assets/icons/arrow.svg" alt="Arrow icon" />
            </div>
            <div (click)="sortBy(sortByOptions.ACCNO_DESC)">
              <div>AccNo.</div>
              <img src="../../../assets/icons/arrow.svg" alt="Arrow icon" />
            </div>
          </div>
        </div>
        <button class="button primary--white four-radius" (click)="download()">
          <span>Download</span>
        </button>
      </div>
    </div>
    <div class="filter">
      <div class="filter-list">
        <span class="label">Filter</span>
        <app-multi-select-dropdown class="multiselect" [clicked]="clicked" [items]="regions" [label]="buName + 's'"
          [borderRadius]="'0.4rem'" [showMore]="false" [requireApply]="true"
          (shareIndividualCheckedList)="selectRegion($event)" (applyFilterEmitter)="reloadTable(1)">
        </app-multi-select-dropdown>
        <app-multi-select-dropdown class="multiselect" [clicked]="clicked" [items]="areas" [label]="utName + 's'"
          [borderRadius]="'0.4rem'" [showMore]="false" [requireApply]="true"
          (shareIndividualCheckedList)="selectArea($event)" (applyFilterEmitter)="reloadTable(1)">
        </app-multi-select-dropdown>
        <select name="tariff-select" (change)="selectTariff($event)" class="form-select">
          <option value="0" [selected]="tariff == 0" disabled>Tariff</option>
          <option [selected]="tariff == o.id" *ngFor="let o of tariffs" value="{{ o.id }}">
            {{ o.name }}
          </option>
        </select>

        <select *ngIf="
              isServiceTypeFieldVisible 
            " name="st-select" (change)="selectUserType($event)" class="form-select">
          <option value="" [selected]="!userType" disabled>
            User type
          </option>
          <option [selected]="userType == o.value" *ngFor="let o of userTypeOptions" value="{{ o.value }}">
            {{ o.name }}
          </option>
        </select>

        <app-multi-select-dropdown class="multiselect" [searchFilterActive]="true" [borderRadius]="'0.4rem'"
          (filterValue)="applySearchFilter($event)" [clicked]="clicked" [items]="filteredUsers" [label]="'Users'"
          [showMore]="false" [requireApply]="true" (shareIndividualCheckedList)="selectUsers($event)"
          (applyFilterEmitter)="reloadTable(1)">
        </app-multi-select-dropdown>
        <select name="tag-select" (change)="selectTag($event)" class="form-select">
          <option value="" [selected]="!tag" disabled>Tag</option>
          <option [selected]="tag == t.value" *ngFor="let t of tagOptions" value="{{ t.value }}">
            {{ t.name }}
          </option>
        </select>
        <select name="slt-select" (change)="selectSltStatus($event)" class="form-select">
          <option value="" [selected]="!sltStatus" disabled>
            {{ sltStatusName }}
          </option>
          <option [selected]="sltStatus == s.key" *ngFor="let s of sltOptions" value="{{ s.key }}">
            {{ s.value }}
          </option>
        </select>

        <select name="uop-select" (change)="selectUseOfPremises($event)" class="form-select">
          <option value="" [selected]="!useOfPremises" disabled>
            Use of premises
          </option>
          <option [selected]="useOfPremises == u.value" *ngFor="let u of uopOptions" value="{{ u.value }}">
            {{ u.name }}
          </option>
        </select>
      </div>
      <div *ngIf="selectedFilters.length > 0">
        <app-filter-checklist [filters]="selectedFilters" (resetFilterEmitter)="resetFilter($event)"
          (removeFilterEmitter)="removeFilter($event)">
        </app-filter-checklist>
      </div>
    </div>
    <div class="table">
      <div *ngIf="customers">
        <table class="table">
          <thead class="table-light">
            <tr>
              <th class="checkbox">
                <input type="checkbox" class="form-check-input" [ngModel]="allCheckboxesChecked"
                  (click)="checkAllCheckboxes()" />
              </th>
              <th>Slrn</th>
              <th>Building Owner</th>
              <th>Acct. no.</th>
              <th>Meter no.</th>
              <th>DT name</th>
              <th></th>
              <th>{{ buName }}</th>
              <th>{{ utName }}</th>
              <th>Tariff</th>
              <th>Validation Status</th>
              <th>Tag</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let customer of customers; let i = index">
              <td>
                <input type="checkbox" (click)="checkCustomer(customer.id)" class="form-check-input"
                  [ngModel]="customer.isChecked" />
              </td>
              <td>
                {{ customer.slrn | hasValue }}
              </td>
              <td>
                {{ customer.buildingOwner | hasValue }}
              </td>
              <td>
                {{ customer.accountNumber | hasValue }}
              </td>
              <td>
                {{ customer.meterNo | hasValue }}
              </td>
              <td>
                {{ customer.dtName | hasValue }}
              </td>
              <td>
                {{ customer.physicalAddress | hasValue }}
              </td>
              <td>
                {{ customer.area.regionName | hasValue }}
              </td>
              <td>
                {{ customer.area.name | hasValue }}
              </td>
              <td>
                {{ customer.tariffName | hasValue }}
              </td>
              <td>
                <div *ngIf="
                      !customer.dqStatus &&
                      customer.assetStatus !== AssetStatus.Assigned
                    " class="gray-status v-2">
                  Pending
                </div>
                <div *ngIf="customer.assetStatus === AssetStatus.Assigned" class="orange-status v-2">
                  Assigned
                </div>
                <div *ngIf="
                      customer.dqStatus &&
                      customer.dqStatus?.toString() == 'Approved' &&
                      customer.assetStatus !== AssetStatus.Assigned
                    " class="green-status v-2">
                  Complete
                </div>
                <div *ngIf="
                      customer.dqStatus &&
                      customer.dqStatus?.toString() == 'Rejected' &&
                      customer.assetStatus !== AssetStatus.Assigned
                    " class="dark-red-status v-2">
                  Rejected
                </div>
              </td>
              <td>
                <div *ngIf="!customer.isOnBoard">
                  <div *ngIf="!customer.isNew" class="dark-green-status v-2">
                    Existing
                  </div>
                  <div *ngIf="customer.isNew" class="green-status v-2">
                    New entry
                  </div>
                </div>
                <div *ngIf="customer.isOnBoard">
                  <div class="olive-green-status v-2">Direct Connection</div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div>
      <app-paging *ngIf="count > 0" [pageSize]="pageSize" [useDirect]="true" [count]="count" [currentPage]="currentPage"
        (pageChange)="pageChange($event)"></app-paging>
    </div>
  </div>
</div>