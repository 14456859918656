import { Location } from '@angular/common';
import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {
  Subject,
} from 'rxjs';
import { Area } from 'src/app/models/area.model';
import {
  AccountStatus,
  CustomerBillingAccountAdd,
  ServiceType,
} from 'src/app/models/customer.model';
import { DtCustomerCreate } from 'src/app/models/dt.model';
import { Feeder, FeederType } from 'src/app/models/feeder.model';
import { HistoryOutput } from 'src/app/models/history.model';
import {
  MeterCondition,
  MeterType,
} from 'src/app/models/meter.model';
import { Region } from 'src/app/models/region.model';
import { Tariff } from 'src/app/models/tariff.model';
import { User } from 'src/app/models/user.model';
import { OptionObj } from 'src/app/models/util.model';
import { AreaService } from 'src/app/services/area.service';
import { BillingAccountService } from 'src/app/services/billing-account.service';
import { CustomerService } from 'src/app/services/customer.service';
import { ReadingService } from 'src/app/services/reading.service';
import { RegionService } from 'src/app/services/region.service';
import { TariffService } from 'src/app/services/tariff.service';
import { TranslationService } from 'src/app/services/translation.service';
import { UserService } from 'src/app/services/user.service';
import { ValidationService } from 'src/app/services/validation.service';

@Component({
  selector: 'app-add-billing-account',
  templateUrl: './add-billing-account.component.html',
  styleUrls: ['./add-billing-account.component.scss']
})
export class AddBillingAccountComponent implements OnInit, OnDestroy {
  @ViewChild('closeModal') closeModal: ElementRef;
  // openUpdateDescriptionModal: ElementRef;
  history: HistoryOutput = new HistoryOutput();

  isDropdownOpened: boolean = false;
  filteredMobileUsers: User[] = [];
  tempAssignedUserId: number = 0;
  mobileUsers: User[] = [];
  assignedUserId: number = 0;

  readonly cardsCount = 4;
  activeCard = 1;
  saveDisabled: boolean = true;
  successfullyAdded: boolean = false;
  processesFinished: number = 0;

  feederType: FeederType = FeederType.NOT_SELECTED;
  selectedFeederId: number = 0;
  buName: string = this.translationService.getByKeyFromStorage('BU');
  utName: string = this.translationService.getByKeyFromStorage('UT');
  contractNumber: string =
    this.translationService.getByKeyFromStorage('Contract number');
  utilityId: number = 0;
  sltOptions: OptionObj[] = [];

  filterText: string = '';

  billingAccount: CustomerBillingAccountAdd = {
    id: 0,
    customerId: 0,
    region: '',
    area: '',
    accountNumber: '',
    meterNumber: '',
    contractNumber: '',
    tariff: '',
    sltStatusId: 0,
    slt: '',
    accountStatus: AccountStatus.NotSelected,
    address: '',
    meterCode: '',
    meterDigit: 0,
    geoCode: '',
    itinerary: '',
    round: '',
    block: '',
    plot: '',
    readingFrequency: 0,
    regionId: 0,
    areaId: 0,
    tariffId: 0,
    utilityId: 0,
    latitude: undefined,
    longitude: undefined
  };

  MeterCondition = MeterCondition;
  meterConditionOptions = [
    { name: 'Working', value: MeterCondition.Working },
    { name: 'NotWorking', value: MeterCondition.NotWorking },
    { name: 'Burnt', value: MeterCondition.Burnt },
  ];
  ServiceType = ServiceType;
  serviceTypeOptions = [
    { name: 'Large', value: ServiceType.Large },
    { name: 'Small', value: ServiceType.Small },
    { name: 'Prepaid', value: ServiceType.Prepaid },
    { name: 'AMR', value: ServiceType.AMR },
  ];
  
 accountStatusOptions = [
   { name: 'Active', value: 1 },
   { name: 'Inactive', value: 2 }
 ];

  //from back
  regions: Region[] = [];
  areas: Area[] = [];
  selectableAreas: Area[] = [];
  tariffs: Tariff[] = [];
  dts: DtCustomerCreate[] = [];
  meterTypes: MeterType[] = [];
  feeders: Feeder[] = [];

  //select boxes
  //1
  accountStatus: string = '';
  area: string = '';
  region: string = '';
  buildingType: string = '';
  serviceType: string = '';
  clientType: string = '';
  typeOfConnection: string = '';
  spn: string | null = null;
  sltStatus: string = this.translationService.getByKeyFromStorage('SltStatus');
  latitude: number = 0;
  longitude: number = 0;
  //2
  tariff?: string = '';

  //3
  meterType: string = '';
  meterCondition: string = '';

  billingAccountId: number = 0;

  selectedDt: DtCustomerCreate = {
    externalId: 0,
    name: '',
    number: '',
    connectedFeederNumber: '',
  };
  dtName: string = this.translationService.getByKeyFromStorage('DTNumber');
  selectedSltStatus: OptionObj = {
    key: 0,
    value: '',
  };

  filterMobileUsers() {
    this.isDropdownOpened = true;
    this.tempAssignedUserId = 0;
    this.getMobileUsers();
  }

  private ngUnsubscribe = new Subject<void>();

  constructor(
    private location: Location,
    private regionService: RegionService,
    private areaService: AreaService,
    private customerService: CustomerService,
    private billingAccountService: BillingAccountService,
    private tariffService: TariffService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
    private translationService: TranslationService,
    private validationService: ValidationService,
    private userService: UserService,
    private readingService: ReadingService
  ) {}

  ngOnInit(): void {
    let utilityId = parseInt(
      this.route.snapshot.paramMap.get('utilityId') || ''
    );
    this.utilityId = utilityId;
    this.getData();
  }

  getSltStatus(value?: string) {
    return this.translationService.getByKeyFromStorage(value ?? '');
  }

  handleBillingAccountData(responseData: any) {
    this.billingAccount = responseData.data;
    this.billingAccount.meterDigit = +this.billingAccount.meterDigit;
    this.accountStatus = this.billingAccount.accountStatus != null ? this.billingAccount.accountStatus.toString() : '';
    this.selectedSltStatus.key = this.billingAccount.sltStatusId;
    this.selectedSltStatus.value = this.billingAccount.slt;

    this.tariff = this.getTariffName(this.billingAccount.tariffId)!;
    const area = this.areas.find((x) => x.id === this.billingAccount.areaId);
    this.area = area?.name!;
    const region = this.regions.find((region) => region.id === area?.regionId)!;
    this.billingAccount.regionId = region.id;
    this.region = region.name;
  }

  getBillingAccount() {
    let id = this.route.snapshot.paramMap.get('id') || '';
    if (id) {
      this.billingAccountId = parseInt(id);
      this.billingAccountService.getCrud(this.billingAccountId).subscribe({
        next: (response) => {
          const responseData = response.body;
          if (responseData?.status === 200 || responseData?.status === 'OK') {
            this.handleBillingAccountData(responseData);
          } else {
            this.toastr.error(responseData?.message);
          }
        },
        error: (_) => {
          this.toastr.error('Error occured');
        },
      });
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  continue(): void {
    if(this.activeCard == 1 && !this.validationService.validateValue(this.billingAccount.accountNumber ?? '', 'AccountNumber')){
      this.toastr.error('Invalid Account number')
      return ;
    }

    if(this.activeCard === 2 && !this.validationService.validateValue(this.billingAccount.meterNumber ?? '', 'MeterNumber')){
      this.toastr.error('Invalid Meter number')
      return ;
    }
      
    this.activeCard++;
    if (this.activeCard > this.cardsCount) {
      this.saveDisabled = false;
    }
  }

  setActiveCard(activeCard: number): void {
    this.activeCard = activeCard;
    this.saveDisabled = true;
  }

  cancelClicked() {
    this.location.back();
  }

  save() {
    this.billingAccount.utilityId = this.utilityId;
    this.billingAccount.sltStatusId = this.selectedSltStatus.key;
    this.billingAccount.slt = this.selectedSltStatus.value;

    this.billingAccountService.addBillingAccount(this.billingAccount).subscribe({
      next: (response) => {
        const responseData = response.body;
        if(responseData?.status === 200 || responseData?.status === "OK"){
          this.successfullyAdded = true;
          setTimeout(() => {
            this.successfullyAdded = false;
            this.router.navigate(['/crud/15']);
          }, 2000);
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }

  finishProcess() {
    this.processesFinished++;
    if (this.processesFinished == 4) {
      this.processesFinished = 0;
      this.getBillingAccount();
    }
  }

  getData() {
    this.getRegions();
    this.getAreas();
    this.getTariffs();
    this.getSltStatuses();
  }

  getRegions() {
    this.regionService.getAllForSelect(this.utilityId).subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.regions = responseData.data;
        } else {
          this.toastr.error(responseData?.message);
        }
        this.finishProcess();
      },
      error: (_) => {
        this.toastr.error('Error occured');
        this.finishProcess();
      },
    });
  }

  getAreas() {
    this.areaService.getAllForSelect(this.utilityId).subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.areas = responseData.data;
          this.selectableAreas = responseData.data;
        } else {
          this.toastr.error(responseData?.message);
        }

        this.finishProcess();
      },
      error: (_) => {
        this.finishProcess();
        this.toastr.error('Error occured');
      },
    });
  }

  getTariffs() {
    this.tariffService.getAllForSelect(this.utilityId).subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.tariffs = responseData.data;
        } else {
          this.toastr.error(responseData?.message);
        }
        this.finishProcess();
      },
      error: (_) => {
        this.finishProcess();
        this.toastr.error('Error occured');
      },
    });
  }

  selectAccountStatus(e: any) {
    if (e.target.value == '')
      this.billingAccount.accountStatus = AccountStatus.NotSelected;
    else 
      this.billingAccount.accountStatus = +e.target.value;

    this.accountStatus = AccountStatus[e.target.value];
  }

  selectRegion(e: any) {
    this.billingAccount.regionId = +e.target.value;
    this.selectableAreas = this.areas.filter(
      (area) => area.regionId === this.billingAccount.regionId
    );
    
    this.area = '';

    this.region = this.regions
      .filter((x) => x.id == this.billingAccount.regionId)
      .toString();
  }

  selectArea(e: any) {
    this.billingAccount.areaId = +e.target.value;
    const area = this.areas.find((x) => x.id === this.billingAccount.areaId);
    this.area = area?.name!;
    const region = this.regions.find((region) => region.id === area?.regionId)!;
    this.billingAccount.regionId = region.id;
    this.region = region.name;
  }

  selectTariff(e: any) {
    this.billingAccount.tariffId = +e.target.value;
    this.tariff = this.getTariffName(this.billingAccount.tariffId)!;
  }

  getTariffName(id: number) {
    return this.tariffs.find((x) => x.id === id)?.name;
  }

  getSltStatuses() {
    this.customerService.getSltStatusesForOptions().subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.sltOptions = responseData.data;
        } else if (response?.status == 204) {
        } else {
          this.toastr.error(responseData?.message);
        }
        this.finishProcess();
      },
      error: (_) => {
        this.toastr.error('Error occured');
        this.finishProcess();
      },
    });
  }

  geoCodeChanged() {
    if(this.billingAccount.geoCode && this.billingAccount.geoCode.split('-').length == 6) {
      var geoCodeParts = this.billingAccount.geoCode.split('-');
      this.billingAccount.itinerary = geoCodeParts[0] + '-' + geoCodeParts[1] + '-' + geoCodeParts[2] + '-' + geoCodeParts[3] + '-' + geoCodeParts[4];
      this.billingAccount.block = geoCodeParts[2];
      this.billingAccount.round = geoCodeParts[3];
      this.billingAccount.plot = geoCodeParts[4];
    }
    else {
      this.billingAccount.itinerary = "";
      this.billingAccount.block = "";
      this.billingAccount.round = "";
      this.billingAccount.plot = "";
    }
  }

  compareByKey(option1: OptionObj, option2: OptionObj): boolean {
    return option1 && option2 ? option1.key === option2.key : option1 === option2;
  }

  openReadingModal(){

  }

  onReadingModalFade(){

  }

  getMobileUsers() {
    this.userService
      .getReaderMobileUsers(this.billingAccount.areaId, this.filterText)
      .subscribe({
        next: (response) => {
          const responseData = response.body;
          if (responseData?.status === 200 || responseData?.status === 'OK') {
            this.mobileUsers = responseData.data;
            // if (this.mobileUsers.length == 0) {
            //   this.isDropdownOpened = false;
            // } else {
            //   this.isDropdownOpened = true;
            // }
          } else {
            this.toastr.error(responseData?.message);
          }
        },
        error: (_) => {
          this.toastr.error('Error occured');
        },
      });
  }

  openDropdown() {
    this.isDropdownOpened = true;
  }

  selectFieldAgent(id: number) {
    this.isDropdownOpened = false;
    this.assignedUserId = id;
    this.tempAssignedUserId = id;
    var mobUser = this.mobileUsers.find((x) => x.id == id);
    this.filterText = mobUser!.fullName;
  }

  assignReading(){
    var reading = {
      userId: this.assignedUserId,
      billingAccountId: this.billingAccount.id
    };

    this.readingService
      .assignNewReading(reading)
      .subscribe({
        next: (response) => {
          const responseData = response.body;
          if (responseData?.status === 200 || responseData?.status === 'OK') {
            this.toastr.success('Successfully assigned the reading');
            this.closeModal?.nativeElement?.click();
          } else {
            this.toastr.error(responseData?.message);
          }
        },
        error: (_) => {
          this.toastr.error('Error occured');
        },
      });
  }
}
