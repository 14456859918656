<div class="modal" id="auditReview" tabindex="-1" aria-hidden="true" (hidden.bs.modal)="resetChoices()">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Field Audit</h5>
          <button type="button" #closeAudit class="btn-cancel-modal" data-bs-dismiss="modal" aria-label="Close">
            Cancel
          </button>
        </div>
        <div class="modal-footer updateStatus">
            <ng-container *ngIf="!phoneValidationSelected">
                <div>
                    <h3>Would you like to verify the customer's phone number?</h3>
                </div>
        
                <button type="button" class="btn btn-primary approve" (click)="handlePhoneVerificationSelection($event, true)">
                    Yes
                </button>
                <button type="button" (click)="handlePhoneVerificationSelection($event, false)" class="btn btn-danger">
                    No
                </button>
            </ng-container>
            <ng-container *ngIf="phoneValidationAgreed">
                <div class="conditional-select-section">
                    <label for="verificationSuccess">Was the phone number verification successful?</label>
                    <select id="verificationSuccess" class="form-control conditional-select" (change)="selectVerificationSuccess($event)">
                        <option [value]="null" selected disabled>Select an option</option>
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                    </select>
                </div>
                <div class="conditional-select-section" *ngIf="phoneValidationSuccessful != undefined && !phoneValidationSuccessful">
                    <label for="phoneNoIssue">Please choose the reason for the unsuccessful verification:</label>
                    <select required id="phoneNoIssue" class="form-control conditional-select" (change)="selectVerificationIssue($event)">
                        <option [value]="null" selected disabled>Select an option</option>
                        <option *ngFor="let item of phoneValidationErrors" [value]="item.value">{{item.name}}</option>
                    </select>
                </div>
            </ng-container>
            <ng-container *ngIf="phoneValidationSelected">
                <div>
                    <h3>You have opted to audit this record</h3>
                </div>
        
                <button type="button" class="btn btn-primary approve" (click)="submitAudit()">
                    Yes
                </button>
                <button type="button" (click)="cancel($event)" class="btn btn-danger">
                    No
                </button>
            </ng-container>
        
        </div>
      </div>
    </div>
  </div>

