<div class="title" style="padding-left: 8px !important;">
    <p>Billing Accounts</p>
    <div class="ml-auto d-flex" style="position: relative;width: 220px;"
      *ngIf="permissionsService.isCustomerCreateEditCrudAllowed && utilityId != 0 && bpsAdminLevel != 2">
      <button class="btn btn-primary btn-add-new" [routerLink]="['/customers/add-billing-account/'+ utilityId]">
        <img src="../../assets/icons/plus.svg" alt="Plus icon">
        <span>New Billing Account</span>
      </button>
    </div>
  </div>
  
  <div class="filter">
    <div class="filter-list">
      Filter
      <app-multi-select-dropdown class="multiselect" [clicked]="clicked" [items]="regions" [label]="buName+'s'"
        [showMore]="false" [requireApply]="true" (shareIndividualCheckedList)="selectRegion($event)">
      </app-multi-select-dropdown>

      <app-multi-select-dropdown class="multiselect" [clicked]="clicked" [items]="areas" [label]="utName+'s'"
        [showMore]="false" [requireApply]="true" (shareIndividualCheckedList)="selectArea($event)">
      </app-multi-select-dropdown>

      <select name="tariff-select" (change)="selectTariff($event)" class="form-select">
        <option value="0" [selected]="tariff == 0" disabled>Tariff Class</option>
        <option [selected]="tariff == o.id" *ngFor="let o of tariffs" value="{{o.id}}">
          {{o.name}}
        </option>
      </select>

      <select name="slt-select" (change)="selectSltStatus($event)" class="form-select">
        <option value="" [selected]="!sltStatus" disabled>Slt status</option>
        <option [selected]="sltStatus === s.key" *ngFor="let s of sltOptions" value="{{s.key}}">
          {{s.value}}
        </option>
      </select>
  
      <select name="status-select" (change)="selectStatus($event)" class="form-select">
        <option value="" [selected]="!accountStatus" disabled>Account Status</option>
        <option [selected]="accountStatus == s.value" *ngFor="let s of accountStatusOptions" value="{{s.value}}">
          {{s.name}}
        </option>
      </select>
  
    </div>
    <div *ngIf="selectedFilters.length > 0">
      <app-filter-checklist [filters]="selectedFilters" [useApply]="true" (resetFilterEmitter)="resetFilter($event)"
        (removeFilterEmitter)="removeFilter($event)" (applyFilterEmitter)="applyFilter($event)">
      </app-filter-checklist>
    </div>
  </div>
  
  <div class="container-fluid border-bottom customer-crud-container">
    <table class="customer-table">
      <tr class="header-row">
        <th>{{buName | uppercase}}</th>
        <th>{{utName | uppercase}}</th>
        <th>ACCOUNT NUMBER</th>
        <th>METER NUMBER</th>
        <th>{{contractNumber | uppercase}}</th>
        <th>TARIFF CLASS</th>
        <th>SLT STATUS</th>
        <th>ACCOUNT STATUS</th>
        <th class="table-address" style="padding-left: 15rem !important; padding-right: 15rem !important;">ADDRESS</th>
        <th>METER CODE</th>
        <th>METER DIGIT</th>
        <th class="long-col">GEOCODE</th>
        <th class="long-col">ITINERARY</th>
        <th>ROUND</th>
        <th>BLOCK</th>
        <th>PLOT</th>
        <th></th>
      </tr>
      <tr class="content-wrapper"
        (click)="permissionsService.isBillingAccountsCreateEditAllowed && bpsAdminLevel != 2 ? selectBillingAccount(billAcc.id) : null"
        *ngFor="let billAcc of billingAccounts" data-bs-target="#addNewRegion">
        <td title="{{billAcc.region}}">{{billAcc.region | hasValue}}</td>
        <td title="{{billAcc.area}}">{{billAcc.area | hasValue}}</td>
        <td title="{{billAcc.accountNumber}}">{{billAcc.accountNumber | hasValue}}</td>
        <td title="{{billAcc.meterNumber}}">{{billAcc.meterNumber | hasValue}}</td>
        <td title="{{billAcc.contractNumber}}">{{billAcc.contractNumber | hasValue}}</td>
        <td title="{{billAcc.tariff}}">{{billAcc.tariff | hasValue}}</td>
        <td title="{{billAcc.slt}}">{{billAcc.slt | hasValue}}</td>
        <td title="{{billAcc.accountStatus}}">{{billAcc.accountStatus | hasValue}}</td>
        <td title="{{billAcc.address}}">{{billAcc.address | hasValue}}</td>
        <td title="{{billAcc.meterCode}}">{{billAcc.meterCode | hasValue}}</td>
        <td title="{{billAcc.meterDigit}}">{{billAcc.meterDigit | hasValue}}</td>
        <td title="{{billAcc.geoCode}}">{{billAcc.geoCode | hasValue}}</td>
        <td title="{{billAcc.itinerary}}">{{billAcc.itinerary | hasValue}}</td>
        <td title="{{billAcc.round}}">{{billAcc.round | hasValue}}</td>
        <td title="{{billAcc.block}}">{{billAcc.block | hasValue}}</td>
        <td title="{{billAcc.plot}}">{{billAcc.plot | hasValue}}</td>
        
        <td class="td-svg" (click)="stopPropagation($event); setBillingAccountForDeleteId(billAcc.id)">
          <svg data-bs-toggle="modal" data-bs-target="#deleteRegion" width="22" height="22" viewBox="0 0 22 22"
            xmlns="http://www.w3.org/2000/svg" *ngIf="permissionsService.isBillingAccountsDeleteAllowed">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M11 22C17.0751 22 22 17.0751 22 11C22 4.92487 17.0751 0 11 0C4.92487 0 0 4.92487 0 11C0 17.0751 4.92487 22 11 22ZM11 20C15.9706 20 20 15.9706 20 11C20 6.02944 15.9706 2 11 2C6.02944 2 2 6.02944 2 11C2 15.9706 6.02944 20 11 20ZM6 11C6 10.4477 6.44772 10 7 10H15C15.5523 10 16 10.4477 16 11C16 11.5523 15.5523 12 15 12H7C6.44772 12 6 11.5523 6 11Z"
              fill="#DE350B" />
          </svg>
        </td>
      </tr>
    </table>
  </div>
  <div>
    <app-paging *ngIf="count > 0" [pageSize]="pageSize" [count]="count" [currentPage]="currentPage"
      (pageChange)="pageChange($event)"></app-paging>
  </div>
  
  <div class="modal fade" id="deleteRegion" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header delete-modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">Are you sure you want to delete this billing account?</h5>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" (click)="deleteBillingAccount()">Delete</button>
          <button #closeDeleteModal hidden="true" data-bs-dismiss="modal"></button>
        </div>
      </div>
    </div>
  </div>