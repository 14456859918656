import { Component, OnInit } from '@angular/core';
import { error, Console } from 'console';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject, fromEvent, takeUntil } from 'rxjs';
import { Area } from 'src/app/models/area.model';
import { ChecklistItem } from 'src/app/models/checklist-item.model';
import { CustomerType, TypeOfConnection } from 'src/app/models/customer.model';
import {
  Decommissioning,
  DecommissioningStats,
  DecommissioningStatus,
  DecommissioningStockItemStatus,
  ReasonForDecommission,
} from 'src/app/models/decommissioning';
import { Region } from 'src/app/models/region.model';
import { OptionObj } from 'src/app/models/util.model';
import { AreaService } from 'src/app/services/area.service';
import { DecommissioningService } from 'src/app/services/decommissioning.service';
import { MeterService } from 'src/app/services/meter.service';
import { RegionService } from 'src/app/services/region.service';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-meter-stock-decommissioned-list',
  templateUrl: './meter-stock-decommissioned-list.component.html',
  styleUrls: ['./meter-stock-decommissioned-list.component.scss'],
})
export class MeterStockDecommissionedListComponent implements OnInit {
  stats = new DecommissioningStats();
  decommissionings: Decommissioning[] = [];
  ReasonForDecommission = ReasonForDecommission;

  buName: string = this.translationService.getByKeyFromStorage('BU');
  utName: string = this.translationService.getByKeyFromStorage('UT');
  utilityId: number = 0;
  count: number = 0;
  pageSize: number = 10;
  currentPage: number = 1;
  searchText: string = '';
  searchTextUpdate = new Subject<string>();
  searchFilterApplied: boolean = false;
  searchByOptions = [
    { name: 'MeterNumber', value: 'Meter No.' },
    { name: 'SerialNumber', value: 'Serial No.' },
    { name: 'AccountNumber', value: 'Account Number.' },
    { name: 'SealNumber', value: 'Seal Number.' },
  ];
  selectedSearchType: string = this.searchByOptions[0].name;
  selectedSearchTypeDisplay: string;
  showSelectSearchType: boolean = false;
  dateTypes = [{ name: 'DecommissionDate', value: 'Decommissioning Date' }];
  selectedDateType: string;
  selectedDateTypeDisplay: string;
  showSelectDateType: boolean = false;
  dateFrom: string;
  dateTo: string;

  selectedAreas: number[] = [];
  selectedRegions: number[] = [];
  selectedFilters: ChecklistItem[] = [];
  allAreas: Area[] = [];
  clicked: boolean = false;
  regions: Region[] = [];
  areas: Area[] = [];

  meterType?: number;
  clickObservable: Observable<Event> = fromEvent(document, 'click');
  meterTypeOptions = [
    { name: 'Prepaid', value: CustomerType.Prepaid },
    { name: 'Postpaid', value: CustomerType.Postpaid },
  ];
  meterPhase?: number;
  meterPhaseOptions = [
    { name: 'Single Phase', value: TypeOfConnection.SinglePhase },
    { name: 'Three Phase', value: TypeOfConnection.ThreePhase },
  ];
  stockItemStatus?: number;
  stockItemStatusOptions = [
    { name: 'In Storage', value: DecommissioningStockItemStatus.InStorage },
    { name: 'Awaiting Recycling', value: DecommissioningStockItemStatus.AwaitingRecycling },
    { name: 'Recycled', value: DecommissioningStockItemStatus.Recycled },
  ];
  decommissionedStatus?: number;
  decommissionedStatusOptions = [
    { name: 'Submitted', value: DecommissioningStatus.Submitted },
    { name: 'Approved', value: DecommissioningStatus.Approved },
    { name: 'Assigned', value: DecommissioningStatus.Assigned },
  ];
  meterBrandOptions: OptionObj[] = [];

  DecommissioningStatus = DecommissioningStatus;
  DecommissioningStockItemStatus = DecommissioningStockItemStatus;
  private ngUnsubscribe = new Subject<void>();
  showUpload: boolean = false;
  allCheckboxesChecked: boolean = false;

  constructor(
    private translationService: TranslationService,
    private meterService: MeterService,
    private toastr: ToastrService,
    private decommissioningService: DecommissioningService,
    private regionService: RegionService,
    private areaService: AreaService
  ) {}

  ngOnInit(): void {
    this.subscribeToClickEvent();
    this.setFilters();
    this.getStats();
    this.getMeterBrandsForOptions();
    this.getRegions();
    this.reloadTable(1);
  }

  private subscribeToClickEvent() {
    this.clickObservable
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((e: any) => {
        this.showUpload = false;
        this.showSelectDateType = false;
        this.showSelectSearchType = false;
      });
  }

  getStats() {
    this.getDecommissionStats(this.getFilterObject());
  }

  applyFilter(item: ChecklistItem) {
    this.getStats();
    this.reloadTable(1);
  }

  getDecommissionStats(obj: any) {
    this.decommissioningService.getDecommissionedStats(obj).subscribe({
      next: (resp) => {
        this.stats.burnt = resp.body.data.totalBurnt;
        this.stats.faulty = resp.body.data.totalFaulty;
        this.stats.upgrade = resp.body.data.totalUpgrade;
        this.stats.undefined = resp.body.data.totalUndefined;
        this.stats.total =
          (this.stats.burnt ?? 0) +
          (this.stats.faulty ?? 0) +
          (this.stats.upgrade ?? 0) +
          (this.stats.undefined ?? 0);
      },
      error: (resp) => {
        this.toastr.error('An error occurred.');
      },
    });
  }

  getFilterObject() {
    let obj = {
      search: this.searchText,
      searchType: this.selectedSearchType,
      dateFrom: this.dateFrom ? this.dateFrom : null,
      dateTo: this.dateTo ? `${this.dateTo}T23:59:59` : null,
      dateType: this.selectedDateType,
      selectedAreas: this.selectedAreas.length > 0 ? this.selectedAreas : null,
      selectedRegions:
        this.selectedRegions.length > 0 ? this.selectedRegions : null,
      meterType: this.meterType,
      meterPhase: this.meterPhase,
      decommissionedStatus: this.decommissionedStatus,
      stockItemStatus: this.stockItemStatus,
    };

    return obj;
  }

  getMeterBrandsForOptions() {
    this.meterService.getMeterTypesForOptions().subscribe(
      (response) => {
        this.meterBrandOptions = response.body.data;
      },
      (error) => {
        this.toastr.error('An error occurred.');
      }
    );
  }

  selectSearchType(searchType: any) {
    if (this.searchText && this.searchFilterApplied) {
      this.searchText = '';
      this.search();
    }
    this.selectedSearchType = searchType.name;
    this.selectedSearchTypeDisplay = searchType.value;
    this.showSelectSearchType = false;
  }

  search() {
    this.searchFilterApplied = this.searchText ? true : false;
    this.reloadTable(1);
  }

  reloadTable(page: any = null) {
    this.allCheckboxesChecked = false;
    if (page) this.currentPage = page;

    let obj = {
      pageInfo: {
        page: this.currentPage,
        pageSize: this.pageSize,
        count: this.count,
      },
      filterParams: this.getFilterObject(),
    };

    this.getAll(obj);
  }

  private getAll(obj: any) {
    this.decommissioningService.getAll(obj).subscribe({
      next: (resp) => {
        if (resp.status === 200) {
          this.count = resp.body.data.count ?? this.count;
          this.decommissionings = resp.body.data.data;
        } else {
          this.toastr.error(resp.body.message);
        }
      },
      error: (_) => this.toastr.error('Error occured'),
    });
  }

  selectDateType(dateType: any) {
    this.selectedDateType = dateType.name;
    this.selectedDateTypeDisplay = dateType.value;
    this.showSelectDateType = false;
  }

  datesValid() {
    if (this.dateFrom && this.dateTo && this.dateTo?.toString() != '') {
      const valid: boolean =
        new Date(this.dateFrom).getTime() <
        new Date(`${this.dateTo.toString()}T23:59:59`).getTime();
      return valid;
    }
    return true;
  }

  download() {
    var obj = this.getFilterObject();
    this.decommissioningService.download(obj).subscribe({
      next: (response) => {
        const data = response.body;
        if (data?.status === 'OK' || data?.status === '200') {
          window.location.href = data.data;
        } else if (data?.status === 'Pending' || data?.status === '600') {
          this.toastr.info(data?.message || '');
        } else this.toastr.error(data?.message || 'Request failed');
      },
      error: (error) => console.log(error),
    });
  }

  selectRegion(e: any) {
    //list of areas for selected regions
    this.areas = this.allAreas.filter((area) =>
      this.regions.some(
        (region) => region.id == area.regionId && region.checked
      )
    );
    this.allAreas.forEach((area) => {
      if (!this.areas.some((a) => a.id === area.id)) {
        area.checked = false; //uncheck areas for unchecked regions
      }
    });
    this.createFilterCards();
  }

  selectArea(e: any) {
    this.createFilterCards();
  }

  createFilterCards() {
    this.selectedFilters = [];
    let selectedAreas = this.areas.filter((x) => x.checked);
    this.selectedAreas = this.areas.filter((x) => x.checked).map((x) => x.id);
    this.selectedRegions = this.regions
      .filter((x) => x.checked)
      .map((x) => x.id);

    //areas
    for (var i = 0; i < selectedAreas.length; i++) {
      this.selectedFilters.push(
        new ChecklistItem(this.utName, selectedAreas[i].name)
      );
    }
    if (this.meterPhase) {
      var statusName = TypeOfConnection[this.meterPhase!];
      this.selectedFilters.push(new ChecklistItem('Meter Phase', statusName));
    }
    if (this.meterType) {
      var statusName = CustomerType[this.meterType!];
      this.selectedFilters.push(new ChecklistItem('Meter Type', statusName));
    }
    if (this.decommissionedStatus) {
      var statusName = DecommissioningStatus[this.decommissionedStatus!];
      this.selectedFilters.push(new ChecklistItem('Decommissioned Status', statusName));
    }
    if (this.stockItemStatus) {
      var statusName = DecommissioningStockItemStatus[this.stockItemStatus!];
      this.selectedFilters.push(new ChecklistItem('Stock Item Status', statusName));
    }
  }

  selectMeterType(e: any) {
    if (e.target.value != '') {
      this.meterType = e.target.value;
    } else {
      this.meterType = undefined;
    }
    this.createFilterCards();
  }
  selectMeterPhase(e: any) {
    if (e.target.value != '') {
      this.meterPhase = e.target.value;
    } else {
      this.meterPhase = undefined;
    }
    this.createFilterCards();
  }
  selectDecommissionedStatus(e: any) {
    if (e.target.value != '') {
      this.decommissionedStatus = e.target.value;
    } else {
      this.decommissionedStatus = undefined;
    }
    this.createFilterCards();
  }
  selectStockItemStatus(e: any) {
    if (e.target.value != '') {
      this.stockItemStatus = e.target.value;
    } else {
      this.stockItemStatus = undefined;
    }
    this.createFilterCards();
  }

  removeFilter(item: ChecklistItem) {
    if (item.property == this.buName) {
      this.regions.forEach((region) => {
        if (region.name === item.selectedValue) {
          region.checked = false;
        }
      });
      this.selectRegion(event);
      this.clicked = !this.clicked;
    } else if (item.property == this.utName) {
      this.areas.forEach((area) => {
        if (area.name === item.selectedValue) {
          area.checked = false;
        }
      });
      this.clicked = !this.clicked;
    } else if (item.property == 'Meter Phase') {
      this.meterPhase = undefined;
    } else if (item.property == 'Meter Type') {
      this.meterType = undefined;
    } else if (item.property == 'Decommissioned Status') {
      this.decommissionedStatus = undefined;
    } else if (item.property == 'Stock Item Status') {
      this.stockItemStatus = undefined;
    }
    this.createFilterCards();
    this.reloadTable(1);
  }

  resetFilter(e: any) {
    this.selectedFilters = [];
    this.regions.forEach((region) => {
      region.checked = false;
    });
    this.areas.forEach((area) => {
      area.checked = false;
    });
    this.areas = [];
    this.selectedAreas = [];
    this.meterPhase = undefined;
    this.meterType = undefined;
    this.decommissionedStatus = undefined;
    this.stockItemStatus = undefined;
    this.selectedRegions = [];
    this.clicked = !this.clicked;
    this.reloadTable(1);
  }

  checkAllCheckboxes() {
    this.decommissionings.forEach(
      (x) => (x.isChecked = !this.allCheckboxesChecked)
    );
    this.allCheckboxesChecked = !this.allCheckboxesChecked;
  }

  checkDecommissioning(id: number) {
    const decommissionings = this.decommissionings.find((x) => x.id === id);
    if (decommissionings) {
      decommissionings.isChecked = !decommissionings.isChecked;
      this.areAllCheckboxesChecked();
    }
  }

  areAllCheckboxesChecked() {
    this.allCheckboxesChecked = this.decommissionings.every((x) => x.isChecked);
  }

  pageChange(value: any) {
    this.currentPage = value;
    this.reloadTable();
  }

  setFilterStorage() {
    let obj = {
      pageInfo: {
        page: this.currentPage,
        pageSize: this.pageSize,
        count: this.count,
      },
      filterParams: this.getFilterObjectForStorage(),
      utilityId: this.utilityId,
    };

    localStorage.setItem(
      'filterDecommissioned',
      JSON.stringify({ type: 'MeterStockDecommissioned', filter: obj })
    );
  }

  getFilterObjectForStorage() {
    let obj = {
      search: this.searchText,
      dateFrom: this.dateFrom ?? null,
      dateTo: this.dateTo ?? null,
      dateType: this.selectedDateType,
      searchType: this.selectedSearchType,
      meterType: this.meterType ?? null,
      meterPhase: this.meterPhase ?? null,
      stockItemStatus: this.stockItemStatus ?? null,
      decommissionedStatus: this.decommissionedStatus ?? null,
      selectedAreas: this.selectedAreas.length > 0 ? this.selectedAreas : null,
      selectedRegions:
        this.selectedRegions.length > 0 ? this.selectedRegions : null,
    };
    return obj;
  }

  setFilters() {
    var filter = localStorage.getItem('filterDecommissioned');
    if (!filter) return;
    var filterObject = JSON.parse(filter);
    if (filterObject.type != 'MeterStockDecommissioned') return;
    this.currentPage = filterObject.filter.pageInfo.page;
    this.pageSize = filterObject.filter.pageInfo.pageSize;
    this.count = filterObject.filter.pageInfo.count;
    this.searchText = filterObject.filter.filterParams.search;
    this.dateFrom = filterObject.filter.filterParams.dateFrom;
    this.dateTo = filterObject.filter.filterParams.dateTo;
    this.selectedDateType = filterObject.filter.filterParams.dateType;
    this.selectedSearchType = filterObject.filter.filterParams.searchType;
    this.meterPhase = filterObject.filter.filterParams.meterPhase;
    this.meterType = filterObject.filter.filterParams.meterType;
    this.decommissionedStatus = filterObject.filter.filterParams.decommissionedStatus;
    this.stockItemStatus = filterObject.filter.filterParams.stockItemStatus;
    this.selectedDateTypeDisplay =
      this.dateTypes.find(
        (type) => type.name == filterObject.filter.filterParams.dateType
      )?.value ?? this.dateTypes[0].value;
    this.selectedSearchTypeDisplay =
      this.searchByOptions.find(
        (type) => type.name == filterObject.filter.filterParams.searchType
      )?.value ?? this.searchByOptions[0].value;
    this.selectedAreas = filterObject.filter.filterParams.selectedAreas
      ? filterObject.filter.filterParams.selectedAreas
      : [];
    this.selectedRegions = filterObject.filter.filterParams.selectedRegions
      ? filterObject.filter.filterParams.selectedRegions
      : [];
  }

  getRegions() {
    this.regionService.getAllForSelect().subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.regions = responseData.data;
          this.setCheckedRegions();
          this.getAreas();
        } else if (response?.status == 204) {
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }

  getAreas() {
    this.areaService.getAllForSelect().subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.allAreas = responseData.data;
          this.areas = this.allAreas.filter((area) =>
            this.regions.some(
              (region) => region.id == area.regionId && region.checked
            )
          );
          this.setCheckedAreas();
          this.createFilterCards();
          this.removeFilterStorage();
        } else if (response?.status == 204) {
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }

  setCheckedRegions() {
    var filter = localStorage.getItem('filterDecommissioned');
    if (!filter) return;
    var filterObject = JSON.parse(filter);
    if (filterObject.type != 'MeterStockDecommissioned') return;
    if (filterObject.filter.filterParams.selectedRegions) {
      this.selectedRegions = filterObject.filter.filterParams.selectedRegions;
      this.regions.forEach((element) => {
        if (this.selectedRegions.includes(element.id)) element.checked = true;
        else element.checked = false;
      });
    }
  }

  setCheckedAreas() {
    var filter = localStorage.getItem('filterDecommissioned');
    if (!filter) return;
    var filterObject = JSON.parse(filter);
    if (filterObject.type != 'MeterStockDecommissioned') return;
    if (filterObject.filter.filterParams.selectedAreas) {
      this.selectedAreas = filterObject.filter.filterParams.selectedAreas;
      this.areas = this.allAreas.filter((area) =>
        this.regions.some(
          (region) => region.id == area.regionId && region.checked
        )
      );
      this.areas.forEach((element) => {
        if (this.selectedAreas.includes(element.id)) element.checked = true;
        else element.checked = false;
      });
    }
  }

  removeFilterStorage() {
    if (this.allAreas.length > 0 && this.regions.length > 0)
      localStorage.removeItem('filterDecommissioned');
  }
}
