import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from '../config/config';
import {
  Customer,
  CustomerBillingAccount,
  CustomerCrudTable,
  CustomerImage,
  CustomerStats,
  RegularizationStats,
  SltStatus,
} from '../models/customer.model';
import { PaginationDto, ResponsePackage } from '../models/util.model';
import { Observable, catchError, throwError } from 'rxjs';
import { NewServiceStats } from '../models/new-service-stats.model';

@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  private pathApi = this.config.setting['pathApi'] + 'customer/';
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(private http: HttpClient, private config: AppConfig) {}

  getCustomersStats(obj: any) {
    return this.http.post<ResponsePackage<CustomerStats>>(
      `${this.pathApi}getStats`,
      obj,
      { headers: this.headers, observe: 'response' }
    );
  }

  getOnboardedCustomersStats(obj: any) {
    return this.http.post<ResponsePackage<NewServiceStats>>(
      `${this.pathApi}getOnboardedStats`,
      obj,
      { headers: this.headers, observe: 'response' }
    );
  }

  getAll(obj: any) {
    return this.http.post<ResponsePackage<PaginationDto<Customer>>>(
      `${this.pathApi}getAll`,
      obj,
      { headers: this.headers, observe: 'response' }
    );
  }

  getAllOnboarded(obj: any) {
    return this.http.post<ResponsePackage<PaginationDto<Customer>>>(
      `${this.pathApi}getAllOnboarded`,
      obj,
      { headers: this.headers, observe: 'response' }
    );
  }

  getAllCrud(obj: any) {
    return this.http.post<ResponsePackage<PaginationDto<CustomerCrudTable>>>(
      `${this.pathApi}getAllCrud`,
      obj,
      { headers: this.headers, observe: 'response' }
    );
  }

  uploadFile(
    obj: any,
    isNewConnection: string,
    utilityId: number
  ): Observable<any> {
    return this.http.post(
      `${this.pathApi}uploadFile?isNewConnection=${isNewConnection}&utilityId=${utilityId}`,
      obj
    );
  }
  uploadRegularizationFile(obj: any, utilityId: number): Observable<any> {
    return this.http.post(
      `${this.pathApi}uploadRegularizationFile?utilityId=${utilityId}`,
      obj
    );
  }
  generateTemplate(utilityId: number): Observable<any> {
    return this.http.get(
      `${this.pathApi}generateTemplate?utilityId=${utilityId}`
    );
  }
  generateRegularizationTemplate(utilityId: number): Observable<any> {
    return this.http.get(
      `${this.pathApi}generateRegularizationTemplate?utilityId=${utilityId}`
    );
  }
  get(id: number) {
    return this.http
      .get<ResponsePackage<any>>(`${this.pathApi}${id}`, {
        headers: this.headers,
        observe: 'response',
      })
      .pipe(
        catchError((error) => {
          console.error('HTTP request error:', error);
          return throwError(() => new Error('HTTP request error'));
        })
      );
  }

  getCustomerForMeter(id: number) {
    return this.http.get<ResponsePackage<any>>(
      `${this.pathApi}getCustomerForMeter?id=${id}`,
      { headers: this.headers, observe: 'response' }
    );
  }

  getCrud(id: number) {
    return this.http.get<ResponsePackage<any>>(
      `${this.pathApi}getCrud?id=${id}`,
      { headers: this.headers, observe: 'response' }
    );
  }
  addCustomer(obj: any) {
    return this.http.post<ResponsePackage<string>>(`${this.pathApi}save`, obj, {
      headers: this.headers,
      observe: 'response',
    });
  }

  download(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}downloadFile`, obj, {
      headers: this.headers,
      observe: 'response',
    });
  }
  downloadReplacedSlrn(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}downloadReplacedSlrnFile`, obj, {
      headers: this.headers,
      observe: 'response',
    });
  }
  uploadPicture(obj: any, image: File, guid: string) {
    let formData: FormData = new FormData();
    formData.append('entityId', obj.customerId);
    formData.append('image', image, image.name);
    formData.append('imageType', obj.imageType);
    formData.append('guid', guid);
    return this.http.post<ResponsePackage<CustomerImage>>(
      `${this.pathApi}uploadBillPicture`,
      formData,
      { observe: 'response' }
    );
  }

  getSltStatusesForOptions(utilityId: number | null = null): Observable<any> {
    let url = `${this.pathApi}getSltStatusesForOptions`;

    if (utilityId !== null) {
      url += `?utilityId=${utilityId}`;
    }

    return this.http.get(url, { observe: 'response' });
  }

  updateStatus(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}updateStatus`, obj, {
      headers: this.headers,
      observe: 'response',
    });
  }

  getCustomerByAccNo(accountNumber: string | null): Observable<any> {
    return this.http.get(`${this.pathApi}getCustomerByAccNo/${accountNumber}`, {
      observe: 'response',
    });
  }

  getCustomerByAccNoForKYC(accountNumber: string): Observable<any> {
    return this.http.get(
      `${this.pathApi}getCustomerByAccNoForKYC/${accountNumber}`,
      { observe: 'response' }
    );
  }
  delete(id: number): Observable<any> {
    return this.http.delete(`${this.pathApi}delete/${id}`, {
      observe: 'response',
    });
  }

  getCustomerDataQuality(id: number): Observable<any> {
    return this.http.get(`${this.pathApi}getCustomerDataQuality/${id}`, {
      observe: 'response',
    });
  }
  rejectCustomer(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}rejectCustomer`, obj, {
      headers: this.headers,
      observe: 'response',
    });
  }
  approveCustomer(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}approveCustomer`, obj, {
      headers: this.headers,
      observe: 'response',
    });
  }
  auditCustomer(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}auditCustomer`, obj, {
      headers: this.headers,
      observe: 'response',
    });
  }
  getHistory(customerId?: any): Observable<any> {
    return this.http.get(`${this.pathApi}getHistory/` + customerId, {
      observe: 'response',
    });
  }

  getCustomersForSurveyAssignOptions(obj: any): Observable<any> {
    return this.http.post(
      `${this.pathApi}getCustomersForSurveyAssignOptions`,
      obj,
      { headers: this.headers, observe: 'response' }
    );
  }
  getCustomersForInstallationAssignOptions(obj: any): Observable<any> {
    return this.http.post(
      `${this.pathApi}getCustomersForInstallationAssignOptions`,
      obj,
      { headers: this.headers, observe: 'response' }
    );
  }

  recallDataQuality(id: number) {
    return this.http.get<ResponsePackage<any>>(
      `${this.pathApi}recallDataQuality/${id}`,
      { headers: this.headers, observe: 'response' }
    );
  }

  getSltStatusesForCrud(dataIn: any): Observable<any> {
    return this.http.post(`${this.pathApi}getSltStatusesForCrud`, dataIn);
  }

  saveSlt(slt: SltStatus): Observable<any> {
    return this.http.post(`${this.pathApi}saveSltStatus`, slt);
  }

  deleteSlt(id: number): Observable<any> {
    return this.http.delete(`${this.pathApi}deleteSltStatus/${id}`);
  }
  getActivities(): Observable<any> {
    return this.http.get(`${this.pathApi}getActivities`, {
      observe: 'response',
    });
  }
  getPermises(): Observable<any> {
    return this.http.get(`${this.pathApi}getPermises`, {
      observe: 'response',
    });
  }
  getAllRejectionReport(obj: any) {
    return this.http.post<ResponsePackage<PaginationDto<Customer>>>(
      `${this.pathApi}getAllRejectionReport`,
      obj,
      { headers: this.headers, observe: 'response' }
    );
  }

  downloadCustomerRejectionReport(obj: any): Observable<any> {
    return this.http.post(
      `${this.pathApi}downloadCustomerRejectionReport`,
      obj,
      {
        headers: this.headers,
        observe: 'response',
      }
    );
  }

  regularizeCustomer(id: number) {
    return this.http.post<ResponsePackage<string>>(
      `${this.pathApi}regularizeCustomer/${id}`,
      id,
      {
        headers: this.headers,
        observe: 'response',
      }
    );
  }

  getRegularizationStats() {
    return this.http.get<ResponsePackage<RegularizationStats>>(
      `${this.pathApi}getRegularizationStats`,
      { headers: this.headers, observe: 'response' }
    );
  }

  getAllRegularizationReport(obj: any) {
    return this.http.post<ResponsePackage<PaginationDto<Customer>>>(
      `${this.pathApi}getAllRegularizationReport`,
      obj,
      { headers: this.headers, observe: 'response' }
    );
  }

  downloadCustomerRegularizationReport(obj: any): Observable<any> {
    return this.http.post(
      `${this.pathApi}downloadCustomerRegularizationReport`,
      obj,
      {
        headers: this.headers,
        observe: 'response',
      }
    );
  }
}
