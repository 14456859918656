import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from '../config/config';
import { PaginationDto, ResponsePackage } from '../models/util.model';
import { CustomerBillingAccount } from '../models/customer.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BillingAccountService {
  private pathApi = this.config.setting['pathApi'] + 'billingAccount/';
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(private http: HttpClient, private config: AppConfig) {}

  addBillingAccount(obj: any) {
    return this.http.post<ResponsePackage<string>>(`${this.pathApi}addOrUpdate`, obj, {
      headers: this.headers,
      observe: 'response',
    });
  }

  getCustomerBillingAccountInformation(obj: any) {
    return this.http.post<ResponsePackage<PaginationDto<CustomerBillingAccount>>>(
      `${this.pathApi}getAllCrud`,
      obj,
      { headers: this.headers, observe: 'response' }
    );
  }

  delete(id: number): Observable<any> {
    return this.http.delete(`${this.pathApi}delete/${id}`, {
      observe: 'response',
    });
  }
  
  getCrud(id: number) {
    return this.http.get<ResponsePackage<any>>(
      `${this.pathApi}getCrud?id=${id}`,
      { headers: this.headers, observe: 'response' }
    );
  }
  uploadFile(obj: any, utilityId: number): Observable<any> {
    return this.http.post(`${this.pathApi}uploadFile?utilityId=${utilityId}`, obj);
  }  
  generateTemplate(utilityId: number): Observable<any> {
    return this.http.get(`${this.pathApi}generateTemplate?utilityId=${utilityId}`);
  }
  
  getItineraryData(dataIn: any) : Observable<any> {
    return this.http.post(`${this.pathApi}getItineraryData`, dataIn, {
      observe: 'response',
    });
  }
}
