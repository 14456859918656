<div class="title" style="padding-left: 8px !important;">
  <div class="customers-tabs">
    <div class="customer-tab" [ngClass]="{'active': !isNewConnection}" (click)="setNewConnectionPage(false)">
      <span>Customers</span>
    </div>
    <div class="customer-tab" [ngClass]="{'active': isNewConnection}" (click)="setNewConnectionPage(true)">
      <span>New Connection</span>
    </div>
  </div>
  <div class="ml-auto d-flex" style="position: relative;width: 220px;"
    *ngIf="permissionsService.isCustomerCreateEditCrudAllowed && utilityId != 0 && bpsAdminLevel != 2">
    <button class="btn btn-primary btn-upload" (click)="showUpload=!showUpload;" click-stop-propagation>
      <img src="../../assets/icons/chevron-down.svg" alt="Chevron down">
    </button>
    <div *ngIf="showUpload" class="xlsx-upload" [routerLink]="['/upload/2/'+ isNewConnection  + '/' + utilityId]">
      <div><img src="../../assets/icons/upload-arrow.svg" alt="Upload arrow"/></div>
      <div>Upload with XLSX</div>
    </div>
    <button class="btn btn-primary btn-add-new" [routerLink]="['/customers/add/'+ isNewConnection + '/' + utilityId]">
      <img src="../../assets/icons/plus.svg" alt="Plus icon">
      <span>New Customer</span>
    </button>
  </div>
</div>

<div class="filter">
  <div class="filter-list">
    Filter
    <app-multi-select-dropdown class="multiselect" [clicked]="clicked" [items]="regions" [label]="buName+'s'"
      [showMore]="false" [requireApply]="true" (shareIndividualCheckedList)="selectRegion($event)">
    </app-multi-select-dropdown>
    <app-multi-select-dropdown class="multiselect" [clicked]="clicked" [items]="areas" [label]="utName+'s'"
      [showMore]="false" [requireApply]="true" (shareIndividualCheckedList)="selectArea($event)">
    </app-multi-select-dropdown>
    <select name="tariff-select" (change)="selectTariff($event)" class="form-select">
      <option value="0" [selected]="tariff == 0" disabled>Tariff Class</option>
      <option [selected]="tariff == o.id" *ngFor="let o of tariffs" value="{{o.id}}">
        {{o.name}}
      </option>
    </select>

    <select name="ct-select" (change)="selectClientType($event)" class="form-select">
      <option value="" [selected]="!clientType" disabled>Client Type</option>
      <option [selected]="clientType==c.value" *ngFor="let c of clientTypeOptions" value="{{c.value}}">
        {{c.name}}
      </option>
    </select>

    <select name="st-select" (change)="selectServiceType($event)" class="form-select">
      <option value="" [selected]="!serviceType" disabled>Service Type</option>
      <option [selected]="serviceType == o.value" *ngFor="let o of serviceTypeOptions" value="{{o.value}}">
        {{o.name}}
      </option>
    </select>

    <select name="slt-select" (change)="selectSltStatus($event)" class="form-select">
      <option value="" [selected]="!sltStatus" disabled>Slt status</option>
      <option [selected]="sltStatus === s.key" *ngFor="let s of sltOptions" value="{{s.key}}">
        {{s.value}}
      </option>
    </select>

    <select name="status-select" (change)="selectStatus($event)" class="form-select">
      <option value="" [selected]="!status" disabled>Status</option>
      <option [selected]="status == s.value" *ngFor="let s of statusOptions" value="{{s.value}}">
        {{s.name}}
      </option>
    </select>

    <select name="cp-select" (change)="selectCustomerPhase($event)" class="form-select">
      <option value="" [selected]="!customerPhase" disabled>Customer Phase</option>
      <option [selected]="customerPhase == c.value" *ngFor="let c of customerPhaseOptions" value="{{c.value}}">
        {{c.name}}
      </option>
    </select>

  </div>
  <div *ngIf="selectedFilters.length > 0">
    <app-filter-checklist [filters]="selectedFilters" [useApply]="true" (resetFilterEmitter)="resetFilter($event)"
      (removeFilterEmitter)="removeFilter($event)" (applyFilterEmitter)="applyFilter($event)" >
    </app-filter-checklist>
  </div>
</div>

<div class="container-fluid border-bottom customer-crud-container">
  <table class="customer-table">
    <tr class="header-row">
      <th>{{buName | uppercase}}</th>
      <th>{{utName | uppercase}}</th>
      <th>FEEDER</th>
      <th>DT NAME</th>
      <th>DT NUMBER</th>
      <th>BUILDING OWNER NAME</th>
      <th class="table-address" style="padding-left: 15rem !important; padding-right: 15rem !important;">ADDRESS</th>
      <th>ACCOUNT NUMBER</th>
      <th>{{contractNumber | uppercase}}</th>
      <th>METER NUMBER</th>
      <th>SLT STATUS</th>
      <th>TARIFF CLASS</th>
      <th>CUSTOMER TYPE</th>
      <th>ITINERARY</th>
      <th>BLOCK NUMBER</th>
      <th>GEO CODE</th>
      <th>CUSTOMER PHASE</th>
      <th>CLIENT TYPE</th>
      <th>SERVICE TYPE</th>
      <th>ACTIVITY CODE</th>
      <th>CURRENT PLOT CODE</th>
      <th *ngIf="isGhanaPostFieldsVisible">GHANA POST GPS</th>
      <th>STATUS</th>
      <th></th>
      <th></th>
    </tr>
    <tr class="content-wrapper"
      (click)="permissionsService.isCustomerCreateEditCrudAllowed && bpsAdminLevel != 2 ? selectCustomer(customer.id) : null"
      *ngFor="let customer of customers" data-bs-target="#addNewRegion">
      <td title="{{customer.region}}">{{customer.region | hasValue}}</td>
      <td title="{{customer.area}}">{{customer.area | hasValue}}</td>
      <td title="{{customer.feeder}}">{{customer.feeder | hasValue}}</td>
      <td title="{{customer.dtName}}">{{customer.dtName | hasValue}}</td>
      <td title="{{customer.dtNumber}}">{{customer.dtNumber | hasValue}}</td>
      <td title="{{customer.buildingOwnerName}}">{{customer.buildingOwnerName | hasValue}}</td>
      <td title="{{customer.address}}">{{customer.address | hasValue}}</td>
      <td title="{{customer.accountNumber}}">{{customer.accountNumber | hasValue}}</td>
      <td title="{{customer.contractNumber}}">{{customer.contractNumber | hasValue}}</td>
      <td title="{{customer.meterNumber}}">{{customer.meterNumber | hasValue}}</td>
      <td title="{{customer.sltStatus}}">{{customer.sltStatus | hasValue}}</td>
      <td title="{{customer.tariffClass}}">{{customer.tariffClass | hasValue}}</td>
      <td title="{{customer.customerType}}">{{customer.customerType | hasValue}}</td>
      <td title="{{customer.itinerary}}">{{customer.itinerary | hasValue}}</td>
      <td title="{{customer.blockNumber}}">{{customer.blockNumber | hasValue}}</td>
      <td title="{{customer.geoCode}}">{{customer.geoCode | hasValue}}</td>
      <td title="{{customer.typeOfConnection}}">{{customer.typeOfConnection | hasValue}}</td>
      <td title="{{customer.clientType}}">{{customer.clientType | hasValue}}</td>
      <td title="{{customer.serviceType}}">{{customer.serviceType | hasValue}}</td>
      <td title="{{customer.activityCode}}">{{customer.activityCode | hasValue}}</td>
      <td title="{{customer.currentPlotCode}}">{{customer.currentPlotCode | hasValue}}</td>
      <td *ngIf="isGhanaPostFieldsVisible" title="{{customer.ghanaPostGPS}}">{{customer.ghanaPostGPS | hasValue}}</td>
      <td>
        <div *ngIf="customer.assetStatus" class="building-status" [ngClass]="{'complete-building': customer.assetStatus == 'Completed', 
                              'pending-building': customer.assetStatus == 'Pending'}">
          {{customer.assetStatus}}
        </div>
        <div *ngIf="!customer.assetStatus" class="building-status">
          --------
        </div>
      </td>
      <td class="td-svg">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-bs-toggle="modal" data-bs-target="#history"
          (click)="stopPropagation($event); viewHistory(customer.id)" width="22" height="22">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M504 255.531c.253 136.64-111.18 248.372-247.82 248.468-59.015.042-113.223-20.53-155.822-54.911-11.077-8.94-11.905-25.541-1.839-35.607l11.267-11.267c8.609-8.609 22.353-9.551 31.891-1.984C173.062 425.135 212.781 440 256 440c101.705 0 184-82.311 184-184 0-101.705-82.311-184-184-184-48.814 0-93.149 18.969-126.068 49.932l50.754 50.754c10.08 10.08 2.941 27.314-11.313 27.314H24c-8.837 0-16-7.163-16-16V38.627c0-14.254 17.234-21.393 27.314-11.314l49.372 49.372C129.209 34.136 189.552 8 256 8c136.81 0 247.747 110.78 248 247.531zm-180.912 78.784l9.823-12.63c8.138-10.463 6.253-25.542-4.21-33.679L288 256.349V152c0-13.255-10.745-24-24-24h-16c-13.255 0-24 10.745-24 24v135.651l65.409 50.874c10.463 8.137 25.541 6.253 33.679-4.21z" />
        </svg>
      </td>
      <td class="td-svg" (click)="stopPropagation($event); setCustomerForDeleteId(customer.id)">
        <svg data-bs-toggle="modal" data-bs-target="#deleteRegion" width="22" height="22" viewBox="0 0 22 22"
          xmlns="http://www.w3.org/2000/svg" *ngIf="permissionsService.isCustomerDeleteAllowed">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M11 22C17.0751 22 22 17.0751 22 11C22 4.92487 17.0751 0 11 0C4.92487 0 0 4.92487 0 11C0 17.0751 4.92487 22 11 22ZM11 20C15.9706 20 20 15.9706 20 11C20 6.02944 15.9706 2 11 2C6.02944 2 2 6.02944 2 11C2 15.9706 6.02944 20 11 20ZM6 11C6 10.4477 6.44772 10 7 10H15C15.5523 10 16 10.4477 16 11C16 11.5523 15.5523 12 15 12H7C6.44772 12 6 11.5523 6 11Z"
            fill="#DE350B" />
        </svg>
      </td>
    </tr>
  </table>
</div>
<div>
  <app-paging *ngIf="count > 0" [pageSize]="pageSize" [count]="count" [currentPage]="currentPage"
    (pageChange)="pageChange($event)"></app-paging>
</div>

<div class="modal fade" id="deleteRegion" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header delete-modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">Are you sure you want to delete this Customer?</h5>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="deleteCustomer()">Delete</button>
        <button #closeDeleteModal hidden="true" data-bs-dismiss="modal"></button>
      </div>
    </div>
  </div>
</div>

<button class="btn btn-primary" #openUpdateDescriptionModal data-bs-toggle="modal" data-bs-target="#history"
  hidden></button>

<app-history [isViewHistory]="isViewHistory" [historyItems]="historyItems"></app-history>