<div class="container-fluid customers-root">
    <div class="table-root">
        <div class="d-flex justify-content-start switch-toggle transparent">
            <a class="separated-toggle-item" [ngClass]="currentTab == 0 ? 'active' : ''" (click)="changeTab(0)">
                <div>
                    Usage <span class="bubble">{{ stats.usage | number }}</span>
                </div>
            </a>
            <a class="separated-toggle-item" [ngClass]="currentTab == 1 ? 'active' : ''" (click)="changeTab(1)">
                <div>
                    Failed <span class="bubble">{{ stats.failed | number }}</span>
                </div>
            </a>
            <a class="separated-toggle-item" [ngClass]="currentTab == 2 ? 'active' : ''" (click)="changeTab(2)">
                <div>
                    Rejected <span class="bubble">{{ stats.rejected | number }}</span>
                </div>
            </a>
            <a class="separated-toggle-item" [ngClass]="currentTab == 3 ? 'active' : ''" (click)="changeTab(3)">
                <div>
                    Inaccessible <span class="bubble">{{ stats.inaccessible | number }}</span>
                </div>
            </a>
            <a class="separated-toggle-item" [ngClass]="currentTab == 4 ? 'active' : ''" (click)="changeTab(4)">
                <div>
                    Inoperable Meters <span class="bubble">{{ stats.inoperableMeters | number }}</span>
                </div>
            </a>
        </div>
        
        <div class="table-root-child">
            <div class="table-action-wrapper d-flex align-items-center justify-content-between">
                <div class="search-input">
                    <img src="../../assets/icons/search.svg" alt="Search icon" (click)="search()" />
                    <input type="text" placeholder="Search" [(ngModel)]="searchText" (keydown.enter)="search()"
                        (ngModelChange)="this.searchTextUpdate.next($event)" />
                </div>
                <div class="ml-auto d-flex">
                    <div>
                        <div class="d-flex">
                            <div class="datepicker-container">
                                <label for="dateFrom"> From: </label>
                                <input type="date" id="dateFrom" name="dateFrom" [ngClass]="
                                    dateFrom > dateTo
                                        ? 'btn datepicker-icon-error'
                                        : 'btn datepicker-icon'
                                    " [(ngModel)]="dateFrom" (change)="datesValid() == true && search()"
                                />
                            </div>

                            <div class="datepicker-container">
                                <label for="dateTo" class="right-label"> To: </label>
                                <input type="date" id="dateTo" name="dateTo" [ngClass]="
                                    dateFrom > dateTo
                                        ? 'btn datepicker-icon-error'
                                        : 'btn datepicker-icon'
                                    " [(ngModel)]="dateTo" (change)="datesValid() == true && search()"
                                />
                            </div>
                            <div class="sort-by-root" (click)="showSortBy = !showSortBy" click-stop-propagation>
                                <div class="dropdown">
                                    <span>Sort by:</span>
                                    <span>{{ sortByLabelValue.label }}
                                        <img *ngIf="sortByLabelValue.arrow !== 'no'" [ngClass]="{'arrow-up': sortByLabelValue.arrow === 'up'}" src="../../../assets/icons/arrow.svg" />
                                    </span>
                                    <span><img src="../../assets/icons/gray-arrow-down.svg" alt="Gray arrow down" /></span>
                                </div>
                                <div class="dropdown-opened" *ngIf="showSortBy">
                                    <div (click)="sortBy(sortByOptions.NEWEST)">
                                        <div>Newest</div>
                                    </div>
                                    <div (click)="sortBy(sortByOptions.OLDEST)">
                                        <div>Oldest</div>
                                    </div>
                                </div>
                            </div>
                            <button class="btn btn-icon" (click)="download()">
                                <span>Download</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="filter">
                <div class="filter-list">
                    Filter
                    <app-multi-select-dropdown class="multiselect" [clicked]="clicked" [items]="regions"
                        [label]="buName + 's'" [showMore]="false" [requireApply]="true"
                        (shareIndividualCheckedList)="selectRegion($event)" (applyFilterEmitter)="reloadTable(1)">
                    </app-multi-select-dropdown>
                    <app-multi-select-dropdown class="multiselect" [clicked]="clicked" [items]="areas"
                        [label]="utName + 's'" [showMore]="false" [requireApply]="true"
                        (shareIndividualCheckedList)="selectArea($event)" (applyFilterEmitter)="reloadTable(1)">
                    </app-multi-select-dropdown>
                    <app-multi-select-dropdown class="multiselect" [clicked]="clicked" [items]="blocks"
                        [label]= "'Block'" [showMore]="false" [requireApply]="true"
                        (shareIndividualCheckedList)="selectBlock($event)" (applyFilterEmitter)="reloadTable(1)">
                    </app-multi-select-dropdown>
                    <app-multi-select-dropdown class="multiselect" [clicked]="clicked" [items]="rounds"
                        [label]= "'Round'" [showMore]="false" [requireApply]="true"
                        (shareIndividualCheckedList)="selectRound($event)" (applyFilterEmitter)="reloadTable(1)">
                    </app-multi-select-dropdown>
                    <!-- <app-multi-select-dropdown class="multiselect" [clicked]="clicked" [items]="plots"
                        [label]= "'Plot'" [showMore]="false" [requireApply]="true"
                        (shareIndividualCheckedList)="selectPlot($event)" (applyFilterEmitter)="reloadTable(1)">
                    </app-multi-select-dropdown> -->
                    <select name="slt-select" (change)="selectSltStatus($event)" class="form-select">
                        <option value="" [selected]="!sltStatus" disabled>
                            {{ sltStatusName }}
                          </option>
                          <option [selected]="sltStatus == s.key" *ngFor="let s of sltOptions" value="{{ s.key }}">
                            {{ s.value }}
                          </option>
                    </select>
                </div>
                <div *ngIf="selectedFilters.length > 0">
                    <app-filter-checklist [filters]="selectedFilters" (resetFilterEmitter)="resetFilter($event)"
                        (removeFilterEmitter)="removeFilter($event)">
                    </app-filter-checklist>
                </div>
            </div>
            <div class="table">
                <div *ngIf="true">
                    <table class="table border-bottom">
                        <thead class="table-light">
                            <tr>
                                <th>
                                    <input type="checkbox" class="form-check-input" [checked]="isAllCheckBoxChecked()"
                                        (change)="checkAllCheckBox($event)" />
                                </th>
                                <th>Account no.</th>
                                <th>Account name</th>
                                <th>{{ buName }}</th>
                                <th>{{ utName }}</th>
                                <th>Meter number</th>
                                <th>Meter digit</th>
                                <th>Period</th>
                                <th>LAR (Last Reading)</th>
                                <th>PAR (Present Reading)</th>
                                <th>SLT status</th>
                                <th>Validation status</th>
                                <th>Consumption</th>
                                <th>Estimation status</th>
                                <th>Creation date</th>
                                <th>Audit status</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="
                                let meterReading of meterReadings;
                                let i = index
                                " (click)="selectVerification(meterReading.id)">
                                <td>
                                    <input type="checkbox" class="form-check-input" value="{{ meterReading.id }}" />
                                </td>

                                <td (click)="setFilterStorage()">
                                    {{ meterReading.accountNumber | hasValue }}
                                </td>
                                <td (click)="setFilterStorage()">
                                    {{ meterReading?.accountName | hasValue }}
                                </td>
                                <td (click)="setFilterStorage()">
                                    {{ meterReading.region | hasValue }}
                                </td>
                                <td (click)="setFilterStorage()">
                                    {{ meterReading.area| hasValue }}
                                </td>
                                <td (click)="setFilterStorage()">
                                    {{ meterReading.meterNumber | hasValue }}
                                </td>
                                <td (click)="setFilterStorage()">
                                    {{ meterReading.meterDigit | hasValue }}
                                </td>
                                <td (click)="setFilterStorage()">
                                    {{ meterReading.period | hasValue }}
                                </td>
                                <td (click)="setFilterStorage()">
                                    {{ meterReading.lastReading | hasValue }}
                                </td>
                                <td (click)="setFilterStorage()">
                                    {{ meterReading.presentReading | hasValue }}
                                </td>
                                <td (click)="setFilterStorage()">
                                    {{ meterReading.sltStatus | hasValue }}
                                </td>
                                <td (click)="setFilterStorage()">
                                    --
                                    <!-- <div *ngIf="meterReading.status" class="building-status" 
                                    [ngClass]="{
                                        'assigned-building':
                                        meterReading.status.toString() == 'Assigned',
                                        'rejected-building':
                                        meterReading.status.toString() == 'Rejected',
                                        'approved-building':
                                        meterReading.status.toString() == 'Approved',
                                        'unvalidated-building':
                                        meterReading.status.toString() == 'New',
                                        'submitted-building':
                                        meterReading.status.toString() == 'Submitted'
                                    }">
                                        {{ meterReading.status }}
                                        validation status
                                    </div> -->
                                </td>
                                <td (click)="setFilterStorage()">
                                    {{ meterReading.consumption | hasValue }}
                                    <!-- consumption -->
                                </td>
                                <td (click)="setFilterStorage()">
                                    {{ meterReading.estimationStatus | hasValue }}
                                    <!-- estimation status -->
                                </td>
                                <td (click)="setFilterStorage()">
                                    {{ meterReading.creationDate | hasValue }}
                                    <!-- creation date -->
                                </td>
                                <td (click)="setFilterStorage()">
                                    --
                                    <!-- <div class="building-status" [ngClass]="{
                                        'assigned-building': meterReading.tag == 'Onboard',
                                        'approved-building': meterReading.tag == 'Existing'
                                    }">
                                        {{ meterReading.tag | hasValue }}
                                        audit status
                                    </div> -->
                                </td>
                                <td (click)="setFilterStorage()" class="more-icon">
                                    <img src="../../assets/icons/more-icon.svg" alt="More icon" class="more-icon">
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div>
                        <app-paging *ngIf="count > 0" [pageSize]="pageSize" [count]="count" [currentPage]="currentPage"
                            [useDirect]="true" (pageChange)="pageChange($event)"></app-paging>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>